import { Injectable } from '@angular/core';
import { setFnolModel } from '../shared/model/setFnol-model';
import { FNOL_COUNTRY, FNOL_STEPS } from '../config/fnol-constants';
//import { FnolModelUsService } from '../services/fnol-model-us.service';
import { Configuration } from '../../../../app/shared/configuration';
import { URL_PARAMETERS } from '../config/urlParameters.constant';
import * as moment from 'moment';
import { DataStoreService } from './data-store.service';
import { Country } from '../shared/model/country';
import { CommonUtils } from '../shared/utils/common-utils';
import { FnolService } from './fnol.service';
import {  APPLICATION_ID} from '../config/fnol-constants';
//import { commonSetFnolSgModel, setFnolSgModel } from '../fnol-sg/fnol-sg-model';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders } from '@angular/common/http';
import { CacheService } from './../../../shared/services/cache.service';

@Injectable({
    providedIn: 'root'
})
export class FnolModelService {

    claimNumberGenerated: string;
    countryData: Country;
    allPartyData: any = [];
    readonly isCountryAuOrNz = CommonUtils.isCountryAuOrNz;
    constructor( private CacheService: CacheService,
        private config: Configuration,  private fnolService: FnolService,
        private _dataStoreService: DataStoreService) { }

    setClaimNumber(claimNumber) {
        this.claimNumberGenerated = claimNumber;
        this.CacheService.setClaimNumber(claimNumber);
    }

    getFnolRequestBody(
        type: string = "",
        policyDetailsData: any = {},
        phoneCodeListData: any = []
      ) {
       
        let pathObject=window.location.pathname.split('/');
        const requestObj = [];
        // commonSetFnolSgModel["applicationContext"]["userId"] =
        //   this.config.userEmailID;
        //   commonSetFnolSgModel["applicationContext"]["countryCode"] =URL_PARAMETERS.COUNTRY || pathObject[3];
        //   commonSetFnolSgModel["applicationContext"]["lineOfBusiness"] =URL_PARAMETERS.LOB || pathObject[5];
        // commonSetFnolSgModel["claimsDetails"]["userId"] = this.config.userEmailID;
        // commonSetFnolSgModel["claimsDetails"]["claimNumber"] =
        //   this.getClaimNumber();
        // commonSetFnolSgModel["claimsDetails"]["dateofIncident"] =window.location.pathname.includes('nordics') ?  moment(new Date()).format('YYYYMMDD')  :
        //   this.getDetail("dateOfAccident");
    
        // requestObj.push(commonSetFnolSgModel);
    
    
        if (type === "finalSubmit") {
          // requestObj.push(this.generateDocUploadData())
        }
       this.generateFnolModelData()
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m2:ClaimNumber']=this.getClaimNumber();
    
       // return Object.assign({},commonSetFnolSgModel,setFnolModel);
      }
    generateSetFnolHeader(): any {
        return {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'email-id': this.config.userEmailID,
            'transactionID': this.fnolService.getTransactionId() || '',
            'Authorization': 'Basic ' + this.config['authorizationKey_new']
          }),
        };
      }
    
        setFnol(): Observable<object> {
            const setFnolURL = this.config.apiEndPoints.setFnolURL;
            const options = this.generateSetFnolHeader();
            const requestObj = this.getFnolRequestBody();
           return this.fnolService.makeHttpPostCall(setFnolURL, requestObj, options);
          }

    generateClaimNumber() {
        return new Promise((resolve, reject) => {
            let pathObject=window.location.pathname.split('/');
            const request = {
                ApplicationContext: {
                    applicationID: APPLICATION_ID,
                    country: URL_PARAMETERS.COUNTRY || pathObject[3].toUpperCase(),
                    language: URL_PARAMETERS.LANGUAGE ||  pathObject[4]
                },
                ClaimNumberRequest: {
                    CountryCd: URL_PARAMETERS.COUNTRY||  pathObject[3].toUpperCase(),
                    LineOfBusiness:window.location.pathname.includes('nordics') ?  pathObject[5] : this.getDetail('lob') 
                }
            };
            this.fnolService.getClaimNumber(request)
            .subscribe(res => {
                this.setClaimNumber(res['response']['ClaimNo']);
                this.fnolService.setTransactionId(res['transactionId']);
                // this.setFnol().subscribe(res =>{})
                resolve(true);
            }, err => {
                console.log('Error in getting claim number service', err);
                reject(false);
            })
        });
    }

    getClaimNumber(): string {
        return this.claimNumberGenerated ? this.claimNumberGenerated : '';
    }

    clearClaimNumber(): void {
        this.claimNumberGenerated = '';
    }

    getApplicationUserId(): string {
        return this.config.applicationUserId;
    }


    generateFnolModelData() {
        this.resetClaimsPartyData();
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:region'] = this.getDetail('region');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:country'] = URL_PARAMETERS.COUNTRY + '';
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:language'] = URL_PARAMETERS.LANGUAGE + '';
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:lineOfBusiness'] = this.getDetail('lob');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:subLineOfBusiness'] = this.getDetail('slob');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:PolicyNumber'] = this.getDetail('policyNumber');
        // setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:PolicyVersion'] = URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode ? this.fnolModelUsService.getDetail('policyVersion') : '';
        // setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:PolicyStatusCd'] = URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode ? this.fnolModelUsService.getDetail('policyStatus') : '';
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:Coverage']['m1:CoverageTypeCd'] = this.getDetail('coverageTypeCode');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:PolicyHolderName'] = this.getDetail('policyHolderName');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:ContractTerm']['m1:EffectiveDt'] = this.getDetail('policyEffectiveDate');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:ContractTerm']['m1:ExpirationDt'] = this.getDetail('policyExpiryDate');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:CountryCd'] = URL_PARAMETERS.COUNTRY + '';
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m2:AnnualStatementCompanyCode'] = this.getDetail('annualCode');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:requestHeader']['ns1:requestMessageID'] = this.getClaimNumber();
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:userId'] = this.getApplicationUserId();
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:providerSystemPassword'] = this.getClaimNumber();
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:systemDate'] = moment(new Date()).format('YYYYMMDD');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns2:applicationContext']['ns1:providerSystemID'] = this.getDetail('providerSysId');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:LossDt'] = this.getDetail('lossDate');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:LossTime'] = this.getDetail('lossTime');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:ClaimsReported'][0]['m1:ReportedDt'] = this.getDetail('sysDateGmt');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:ClaimsReported'][1]['m1:ReportedDt'] = this.getDetail('sysDateGmt');
        // setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:ClaimTypeCd'] = this.fnolModelUsService.getDetail('claimTypeCode');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:IncidentDesc'] = this.getDetail('incidentDesc');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m2:ClaimNumber'] = this.getClaimNumber();
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:Policy']['m1:CurCd'] = this.getDetail('currency');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:NameInfo']['m1:PersonName']['m1:GivenName'] = this.getDetail('firstName');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:NameInfo']['m1:PersonName']['m1:Surname'] = this.getDetail('lastName');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Addr']['m1:Addr1'] = this.getDetail('address1');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Addr']['m1:Addr2'] = this.getDetail('address2');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Addr']['m1:City'] = this.getDetail('city');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Addr']['m1:CountryCd'] = this.getDetail('countryCode');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Addr']['m1:PostalCode'] = this.getDetail('postalCode');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Addr']['m1:StateProvCd'] = this.getDetail('state');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Addr']['m1:StateProv'] = this.getDetail('state');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Communications']['m1:EmailInfo']['m1:EmailAddr'] = this.getDetail('mail');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:Communications']['m1:PhoneInfo'] = this.getDetail('phoneArray');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:GeneralPartyInfo']['m1:MiscParty']['m1:MiscPartyInfo']['m1:MiscPartySubRoleCd'] = this.getDetail('identifyUs');
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'][0]['m1:PersonInfo']['m1:BirthDt'] = this.getDetail('dob');

		if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz || URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                const brokerData = {
                    'm1:ClaimsPartyInfo': {
                        'm1:ClaimsPartyRoleCd': 'Broker'
                    },
                    'm1:GeneralPartyInfo': {
                        'm2:CommunicationModeCd': '',
                        'm1:NameInfo': {
                            // 'm1:PersonName': {
                            //     'm1:GivenName': this.isCountryAuOrNz ? this.getDetail('brokerName') : this.fnolModelUsService.getDetail('brokerName'),
                            //     'm1:Surname': ''
                            // }
                        },
                        'm1:Addr': {
                            'm1:Addr1': '',
                            'm1:Addr2': '',
                            'm1:City': '',
                            'm1:StateProvCd': '',
                            'm1:PostalCode': '',
                            'm1:CountryCd': '',
                            'm1:StateProv': ''
                        },
                        // 'm1:Communications': {
                        //     'm1:EmailInfo': {
                        //         'm1:EmailAddr': this.isCountryAuOrNz ? this.getDetail('brokerEmail') : this.fnolModelUsService.getDetail('brokerEmail')
                        //     },
                        //     'm1:PhoneInfo': this.isCountryAuOrNz ? this.getDetail('brokerNumber') : this.fnolModelUsService.getDetail('brokerNumber')
                        // },
                        'm1:MiscParty': {
                            'm1:MiscPartyInfo': {
                                '@id': 2,
                                'm1:MiscPartySubRoleCd': 'Broker'
                            }
                        },
                        'm2:IndOrOrgCd': ''
                    },
                    'm1:PersonInfo': {
                        'm1:GenderCd': '',
                        'm1:BirthDt': ''
                    }
                };
    
            // if (this.getDetail('brokerName') !== '' || this.getDetail('brokerEmail') !== '' || (this.getDetail('brokerNumber').length > 0) ) {
            //     setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'].push(brokerData);
            // }
                if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                    const reportByData = {
                        'm1:ClaimsPartyInfo': {
                            'm1:ClaimsPartyRoleCd': 'ReptBy'
                        },
                        'm1:GeneralPartyInfo': {
                            'm2:CommunicationModeCd': '',
                            'm1:NameInfo': {
                                // 'm1:PersonName': {
                                //     'm1:GivenName': this.fnolModelUsService.getDetail('firstName'),
                                //     'm1:Surname': this.fnolModelUsService.getDetail('surName')
                                // }
                            },
                            'm1:Addr': {
                                // 'm1:Addr1': this.fnolModelUsService.getDetail('address1'),
                                // 'm1:Addr2': this.fnolModelUsService.getDetail('address2'),
                                // 'm1:City': this.fnolModelUsService.getDetail('city'),
                                // 'm1:StateProvCd': this.fnolModelUsService.getDetail('state'),
                                // 'm1:PostalCode': this.fnolModelUsService.getDetail('postalCode'),
                                // 'm1:CountryCd': this.fnolModelUsService.getDetail('countryCode'),
                                // 'm1:StateProv': this.fnolModelUsService.getDetail('state')
                            },
                            'm1:Communications': {
                                // 'm1:EmailInfo': {
                                //     'm1:EmailAddr': this.fnolModelUsService.getDetail('claimantEmail')
                                // },
                                // 'm1:PhoneInfo': this.fnolModelUsService.getDetail('phoneArray')
                            },
                            'm1:MiscParty': {
                                'm1:MiscPartyInfo': {
                                    '@id': 3,
                                    'm1:MiscPartySubRoleCd': 'ReptBy'
                                }
                            },
                            'm2:IndOrOrgCd': ''
                        },
                        'm1:PersonInfo': {
                            'm1:GenderCd': '',
                            'm1:BirthDt': ''
                        }
                    };
                   // setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'].push(reportByData);
                }
        }
        /* Question Answers starts */
        if (URL_PARAMETERS.COUNTRY.toLowerCase() !== this.isCountryAuOrNz && URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.NAM.countryCode) {
            setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
                {
                    'm1:QuestionText': 'Identification Number',
                    'm1:YesNoCd': this.getDetail('idNumber')
                },
                {
                    'm1:QuestionText': 'Current Age',
                    'm1:YesNoCd': this.getDetail('currentAge')
                },
                {
                    'm1:QuestionText': 'Title',
                    'm1:YesNoCd': this.getDetail('title')
                },
                {
                    'm1:QuestionText': 'Extension',
                    'm1:YesNoCd': this.getDetail('extn')
                },
                {
                    'm1:QuestionText': 'Any Trip ID/Reference Number?',
                    'm1:YesNoCd': this.getDetail('tripId')
                },
                {
                    'm1:QuestionText': 'Vehicle Plate Number',
                    'm1:YesNoCd': this.getDetail('plateNo')
                },
                {
                    'm1:QuestionText': 'Trip Completion Time',
                    'm1:YesNoCd': this.getDetail('tripEndTime')
                },
                {
                    'm1:QuestionText': 'Job Acceptance Time',
                    'm1:YesNoCd': this.getDetail('jobAcceptTime')
                },
                {
                    'm1:QuestionText': 'Passenger pick-up Commencement Time',
                    'm1:YesNoCd': this.getDetail('pickupTime')
                },
            );
        }
        if ((URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode) || 
            (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode && this.getDetail('paymentType') === 'Bank Transfer') ||
            (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.THAILAND.countryCode && this.getDetail('paymentType') === 'Electronic Bank Transfer')) {
            setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
                {
                    'm1:QuestionText': 'Bank Account No',
                    'm1:YesNoCd': this.getDetail('accountNumber')
                },
                {
                    'm1:QuestionText': 'Bank Name',
                    'm1:YesNoCd': this.getDetail('bankName')
                },
                {
                    'm1:QuestionText': 'Payee Name',
                    'm1:YesNoCd': this.getDetail('payeeName')
                },
                {
                    'm1:QuestionText': 'Bank Code',
                    'm1:YesNoCd': this.getDetail('bankCode')
                }
            );
        }
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
            setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
                {
                    'm1:QuestionText': 'Initial Estimate of Loss',
                    'm1:YesNoCd': this.getDetail('estimateLoss')
                },
                {
                    'm1:QuestionText': 'Description of Loss',
                    'm1:YesNoCd': this.getDetail('lossDesc')
                },
                {
                    'm1:QuestionText': 'Location of Insured Property',
                    'm1:YesNoCd': this.getDetail('locationProp')
                },
                {
                    'm1:QuestionText': 'Bank Account',
                    'm1:YesNoCd': this.getDetail('accountNumber')
                },
                {
                    'm1:QuestionText': 'Bank Name',
                    'm1:YesNoCd': this.getDetail('bankName')
                },
                {
                    'm1:QuestionText': 'Name of Account',
                    'm1:YesNoCd': this.getDetail('payeeName')
                }
            );
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.AUSTRALIA.countryCode) {
                setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(                
                    {
                        'm1:QuestionText': 'BSB',
                        'm1:YesNoCd': this.getDetail('bankCode')
                    },
                    {
                        'm1:QuestionText': 'Are you Registered for GST?',
                        'm1:YesNoCd': this.getDetail('gst')
                    },
                    {
                        'm1:QuestionText': 'What is your ABN Number?',
                        'm1:YesNoCd': this.getDetail('abnNumber')
                    },
                    {
                        'm1:QuestionText': 'Have you claimed or do you intend to claim an input tax credit on the GST applicable to this policy?',
                        'm1:YesNoCd': this.getDetail('gstPolicy')
                    }
                );
            }
        }
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode) {
            setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
                {
                    'm1:QuestionText': 'Branch Code',
                    'm1:YesNoCd': this.getDetail('branchCode')
                }
            );
        }
        // if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //     const claimType = this.fnolModelUsService.getDetail('claimType');
        //     const cargoLoss = this.fnolModelUsService.getDetail('cargoLoss');
        //     const inlandLoss = this.fnolModelUsService.getDetail('inlandLoss');
        //     const noOfVPD = this.fnolModelUsService.getDetail('noOfVPD');
        //     const mtcInvolved = this.fnolModelUsService.getDetail('mtcInvolved');
        //     const sameLocation = this.fnolModelUsService.getDetail('sameLocation');
        //     setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //         {
        //             'm1:QuestionText': 'I am submitting this claim as',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('submitterRole')
        //         },
        //         {
        //             'm1:QuestionText': 'Policy Number',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('policyNumber')
        //         },
        //         {
        //             'm1:QuestionText': 'I do not have the Policy Number',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('havePolicyNoCheck')
        //         }
        //     );
        //     setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //         {
        //             'm1:QuestionText': 'Company Name',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('companyName')
        //         },
        //         {
        //             'm1:QuestionText': 'Certificate Number',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('certNo')
        //         },
        //         {
        //             'm1:QuestionText': 'Date of Loss',
        //             'm1:YesNoCd': this.getDetail('lossDate')
        //         },
        //         {
        //             'm1:QuestionText': 'Initial Estimate of Loss',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('estimateLoss')
        //         },
        //         {
        //             'm1:QuestionText': 'Estimated Cargo Value',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('cargoValue')
        //         },
        //         {
        //             'm1:QuestionText': 'TP Claim Reference',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('claimRef')
        //         },
        //         {
        //             'm1:QuestionText': 'Description of Loss',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('lossDesc')
        //         },
        //         {
        //             'm1:QuestionText': 'Location of Insured Property',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('lossLocation')
        //         },
        //         {
        //             'm1:QuestionText': 'Address Line 1',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('lossLocation')
        //         },
        //         {
        //             'm1:QuestionText': 'Address Line 2',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('lossLocationAddress2')
        //         },
        //         {
        //             'm1:QuestionText': 'City',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('lossLocationCity')
        //         },
        //         {
        //             'm1:QuestionText': 'State',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('lossLocationState')
        //         },
        //         {
        //             'm1:QuestionText': 'Zip/Postal Code',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('lossLocationZipcode')
        //         },
        //         {
        //             'm1:QuestionText': 'Country',
        //             'm1:YesNoCd': this.fnolModelUsService.getDetail('lossLocationCountryCode')
        //         }
        //     )
        //     if (this._dataStoreService.get(FNOL_STEPS.CLAIMANT_INFO.name).uiData.identify === 'Company' && 
        //         (this.fnolModelUsService.getDetail('firstName') !== '' || this.fnolModelUsService.getDetail('lastName') !== '')) {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Reporting Full Name',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('reportingFirstName') + ' ' + this.fnolModelUsService.getDetail('reportingLastName')
        //             }
        //         );
        //     }
        //     if (this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name) && this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name).uiData.cargoIdentify === 'Company' && 
        //         (this.fnolModelUsService.getDetail('cargoFirstName') !== '' || this.fnolModelUsService.getDetail('cargoLastName') !== '')) {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Claimant Full Name',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('cargoFirstNameCaps') + ' ' + this.fnolModelUsService.getDetail('cargoLastNameCaps')
        //             }
        //         );
        //     } else if (this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name) && this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name).uiData.inlandIdentify === 'Company' && 
        //         (this.fnolModelUsService.getDetail('inlandFirstName') !== '' || this.fnolModelUsService.getDetail('inlandLastName') !== '')) {
        //             setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //                 {
        //                     'm1:QuestionText': 'Claimant Full Name',
        //                     'm1:YesNoCd': this.fnolModelUsService.getDetail('inlandFirstNameCaps') + ' ' + this.fnolModelUsService.getDetail('inlandLastNameCaps')
        //                 }
        //             );
        //     }
        //     if (claimType === 'Marine Cargo') {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Select Claim Benefits',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('claimType')
        //             },
        //             {
        //                 'm1:QuestionText': 'Type of Loss',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('cargoLoss')
        //             }
        //         );
        //     }
        //     if (claimType === 'Marine Cargo' && cargoLoss === 'In Transit Loss') {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //               {
        //                 'm1:QuestionText': 'Conveyance',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('conveyance')
        //               },
        //               {
        //                 'm1:QuestionText': 'Carrier',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('carrier')
        //               },
        //               {
        //                 'm1:QuestionText': 'Description of Cargo',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('descriptionCargo')
        //               },
        //               {
        //                 'm1:QuestionText': 'Bill of Lading Number',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('lading')
        //               },
        //               {
        //                 'm1:QuestionText': 'Bill of Lading Date',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('ladingShippingDate')
        //               },
        //               {
        //                 'm1:QuestionText': 'Origin',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('origin')
        //               },
        //               {
        //                 'm1:QuestionText': 'Origin Country',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('originDestination')
        //               }
        //         );
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Arrival Date',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('arrivalDateOC')
        //             },
        //             {
        //                 'm1:QuestionText': 'Additional Information',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('cargoAdditional')
        //             }
        //         );
        //     }
        //     if (claimType === 'Inland Marine') {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Select Claim Benefits',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('claimType')
        //             },
        //             {
        //                 'm1:QuestionText': 'Type of Loss',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('inlandLoss')
        //             },
        //             {
        //               'm1:QuestionText': 'Cargo Product',
        //               'm1:YesNoCd': this.fnolModelUsService.getDetail('cargoProduct')
        //             }
        //         );
        //     }
        //     if (claimType === 'Inland Marine' && inlandLoss === 'VPDMTC') {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Conveyance',
        //                 'm1:YesNoCd': '003'
        //             }
        //         );
        //     }
        //     if (claimType === 'Inland Marine' && inlandLoss === 'VPDMTC' && this.fnolModelUsService.getDetail('inlandAddress1')) {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Arrival Date',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('inlandArrivalDateOC')
        //             },
        //             {
        //                 'm1:QuestionText': 'Additional Information',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('inlandAdditional')
        //             }
        //         );
        //     }
        //     if (claimType === 'Inland Marine' && mtcInvolved === 'Yes') {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Origin',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('originInland')
        //             },
        //             {
        //                 'm1:QuestionText': 'Destination',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('destinationInland')
        //             }
        //         );
        //     }
        //     if (claimType === 'Inland Marine' && inlandLoss === 'VPDMTC' && sameLocation === 'No' 
        //             && this.fnolModelUsService.getDetail('inlandAddress2')) {
        //         setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
        //             {
        //                 'm1:QuestionText': 'Arrival Date',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('inlandArrivalDateMTCOC')
        //             },
        //             {
        //                 'm1:QuestionText': 'Additional Information',
        //                 'm1:YesNoCd': this.fnolModelUsService.getDetail('inlandAdditionalMTC')
        //             }
        //         );
        //     }
        // }
        /* Question Answers ends */

        /* Loss Info starts */
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m2:LossInfo'] = [];
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
            const lossInfoData = {
                'm2:FeatureTypeCd': 'Property Claim - BIC',
                'm2:FeatureNumber': {
                    '@id': true,
                    '$': 1
                },
                'm1:QuestionAnswer': [],
            };
            if (!URL_PARAMETERS.TYPE) {
                if (this.getDetail('description') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Business Description',
                            'm1:YesNoCd': this.getDetail('description')
                        }
                    );
                }
                if (this.getDetail('premises') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Was there a confirmed case of COVID in a person at the Insured\'s premises?',
                            'm1:YesNoCd': this.getDetail('premises')
                        }
                    );
                    if (this.getDetail('premises') === 'Yes' && this.getDetail('caseDate') !== '') {
                        lossInfoData['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'Please provide the confirmed case date(s)',
                                'm1:YesNoCd': this.getDetail('caseDate')
                            }
                        );
                    }
                }
                if (this.getDetail('policy') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Was there a confirmed case of COVID within the radius specified in the policy?',
                            'm1:YesNoCd': this.getDetail('policy')
                        }
                    );
                    if (this.getDetail('policy') === 'Yes' && this.getDetail('confirmedCase') !== '') {
                        lossInfoData['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'Please provide the confirmed date(s) and location(s) of the confirmed case(s)',
                                'm1:YesNoCd': this.getDetail('confirmedCase')
                            }
                        );
                    }
                }
                if (this.getDetail('closedWhole') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Did the Insured\'s business closed in whole?',
                            'm1:YesNoCd': this.getDetail('closedWhole')
                        }
                    );
                    if (this.getDetail('closedWhole') === 'Yes' && this.getDetail('durationDate') !== '') {
                        lossInfoData['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'Please provide the duration in date and time when the business was closed in whole',
                                'm1:YesNoCd': this.getDetail('durationDate')
                            }
                        );
                    }
                }
                if (this.getDetail('insured') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Did the Insured\'s business closed in part but not in whole?',
                            'm1:YesNoCd': this.getDetail('insured')
                        }
                    );
                    if (this.getDetail('insured') === 'Yes' && this.getDetail('partiallyClosed') !== '') {
                        lossInfoData['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'Please provide the specific date(s) on which the business was partially closed',
                                'm1:YesNoCd': this.getDetail('partiallyClosed')
                            }
                        );
                    }
                }
                if (this.getDetail('exactDate') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Please provide the exact date the business closed or partially closed and until when',
                            'm1:YesNoCd': this.getDetail('exactDate')
                        }
                    );
                }
                if (this.getDetail('insuredNotice') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Did the Insured\'s business receive a notice from a government agency of any kind requiring it to close?',
                            'm1:YesNoCd': this.getDetail('insuredNotice')
                        }
                    );
                    if (this.getDetail('insuredNotice') === 'Yes' && this.getDetail('confirmation') !== '') {
                        lossInfoData['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'Please provide confirmation of the date of any government order or mandate that relates to the business operated by the Insured',
                                'm1:YesNoCd': this.getDetail('confirmation')
                            }
                        );
                    }
                }
                if (this.getDetail('restrictions') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Please provide any written documentation received from a government authority addressed specifically to the Insured\'s business in relation to COVID restrictions for closure or partial closure',
                            'm1:YesNoCd': this.getDetail('restrictions')
                        }
                    );
                    if (this.getDetail('restrictions') === 'Yes' && this.getDetail('writtenDoc') !== '') {
                        lossInfoData['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'Please provide any written documentation received from a government authority addressed specifically to the Insured\'s business in relation to COVID restrictions for closure or partial closure',
                                'm1:YesNoCd': this.getDetail('writtenDoc')
                            }
                        );
                    }
                }
                if (this.getDetail('infoAig') !== '') {
                    lossInfoData['m1:QuestionAnswer'].push(
                        {
                            'm1:QuestionText': 'Any additional information you wish to tell AIG?',
                            'm1:YesNoCd': this.getDetail('infoAig')
                        }
                    );
                }
            }
            setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m2:LossInfo'].push(lossInfoData);
        } 
       // else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //     setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m2:LossInfo'] = this.fnolModelUsService.getLossInfo();
        // } 
        else {
            const claimTypes = this.getDetail('claimType');
            let index = 0;
            claimTypes.forEach(claimType => {
                if (claimType === 'medicalExpenseClaim') {
                    index++;
                    const medicalExpense = {
                        'm2:FeatureTypeCd': this.getDetail('featureTypeCd'),
                        'm2:FeatureNumber': {
                            '@id': true,
                            '$': index
                        },
                        'm1:QuestionAnswer': [
                            {
                                'm1:QuestionText': 'When did you visit the doctor?',
                                'm1:YesNoCd': this.getDetail('drVisit')
                            },
                            {
                                'm1:QuestionText': 'What was the diagnosis?',
                                'm1:YesNoCd': this.getDetail('diagnosis')
                            },
                            {
                                'm1:QuestionText': 'Western Medicine',
                                'm1:YesNoCd': this.getDetail('westernMedicine')
                            },
                            {
                                'm1:QuestionText': 'Dental Treatment',
                                'm1:YesNoCd': this.getDetail('dentalTreatment')
                            },
                            {
                                'm1:QuestionText': 'Total Claim Amount',
                                'm1:YesNoCd': this.getDetail('totalExpense')
                            },
                            {
                                'm1:QuestionText': 'Any other Insurance covering this incident for you?',
                                'm1:YesNoCd': this.getDetail('insuranceCover')
                            }
                        ],
                    };
                    if (this.getDetail('insuranceCover') === 'yes') {
                        if (this.getDetail('insuranceName')) {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Other Insurance Name',
                                    'm1:YesNoCd': this.getDetail('insuranceName')
                                },
                            );
                        }
                        if (this.getDetail('claimFiled') !== '') {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Have you filed a claim to this Insurance yet?',
                                    'm1:YesNoCd': this.getDetail('claimFiled')
                                },
                            );
                        }
                        if (this.getDetail('claimFiled') === 'yes' && this.getDetail('gotPayment')) {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Have you received the payment?',
                                    'm1:YesNoCd': this.getDetail('gotPayment')
                                },
                            );
                        }
                    }
                    if ((URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode && 
                        this.getDetail('identifyUs') === 'Passenger') || URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.THAILAND.countryCode || 
                        URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                        medicalExpense['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'Are you a US citizen?',
                                'm1:YesNoCd': this.getDetail('usCitizen')
                            },
                        );
                    }
                    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                        medicalExpense['m1:QuestionAnswer'].splice(4, 0,
                            {
                                'm1:QuestionText': 'Traditional Chinese Medicine',
                                'm1:YesNoCd': this.getDetail('chineseMedicine')
                            }
                        );
                    }
                    if (this.getDetail('hospitalAdmitted') !== '') {
                        medicalExpense['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'Were you admitted to hospital?',
                                'm1:YesNoCd': this.getDetail('hospitalAdmitted')
                            },
                        );
                    }
                    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode && 
                    this.getDetail('hospitalAdmitted') === 'yes') {
                        medicalExpense['m1:QuestionAnswer'].splice(7, 0,
                            {
                                'm1:QuestionText': 'Hospital\'s Address',
                                'm1:YesNoCd': this.getDetail('hospitalAddress1')
                            }
                        );
                    }
                    if (this.getDetail('hospitalAdmitted') === 'yes') {
                        if (this.getDetail('hospitalName')) {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Hospital Name',
                                    'm1:YesNoCd': this.getDetail('hospitalName')
                                },
                            );
                        }
                        if (this.getDetail('dateOfAdmission')) {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Date of admission',
                                    'm1:YesNoCd': this.getDetail('dateOfAdmission')
                                },
                            );
                        }
                        if (this.getDetail('timeOfAdmission')) {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Time of admission',
                                    'm1:YesNoCd': this.getDetail('timeOfAdmission')
                                },
                            );
                        }
                        if (this.getDetail('hospitalizationDischarge')) {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Date of Discharge',
                                    'm1:YesNoCd': this.getDetail('hospitalizationDischarge')
                                },
                            );
                        }
                        if (this.getDetail('dischargeTime')) {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Time of Discharge',
                                    'm1:YesNoCd': this.getDetail('dischargeTime')
                                },
                            );
                        }
                    }
                    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode && 
                        this.getDetail('medicalleaveStartDate') !== '') {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'Start of outpatient medical leave',
                                    'm1:YesNoCd': this.getDetail('medicalleaveStartDate')
                                },
                            );
                    }
                    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode && 
                        this.getDetail('medicalleaveEndDate') !== '') {
                            medicalExpense['m1:QuestionAnswer'].push(
                                {
                                    'm1:QuestionText': 'End of outpatient medical leave',
                                    'm1:YesNoCd': this.getDetail('medicalleaveEndDate')
                                },
                            );
                    }
                    setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m2:LossInfo'].push(medicalExpense);
                } else if (claimType === 'permanentDisability') {
                    index++;
                    const permanentDisability = {
                        'm2:FeatureTypeCd': 'Permanent Disability',
                        'm2:FeatureNumber': {
                            '@id': true,
                            '$': index
                        },
                        'm1:QuestionAnswer': [
                            {
                                'm1:QuestionText': 'When did you visit the doctor?',
                                'm1:YesNoCd': this.getDetail('drVisit')
                            },
                            {
                                'm1:QuestionText': 'Date disability was diagnosed?',
                                'm1:YesNoCd': this.getDetail('pdDate')
                            },
                            {
                                'm1:QuestionText': 'Type of Permanent disability?',
                                'm1:YesNoCd': this.getDetail('pdType')
                            },
                            {
                                'm1:QuestionText': 'Name of Clinic',
                                'm1:YesNoCd': this.getDetail('clinic')
                            }
                        ],
                    };
                    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode) {
                        permanentDisability['m1:QuestionAnswer'].push({
                            'm1:QuestionText': 'Clinic / Hospital\'s Address',
                            'm1:YesNoCd': this.getDetail('hospitalAddress')
                        });
                    }
                    setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m2:LossInfo'].push(permanentDisability);
                } else if (claimType === 'accidentalDeath') {
                    index++;
                    const accidentalDeath = {
                        'm2:FeatureTypeCd': 'Accidental Death',
                        'm2:FeatureNumber': {
                            '@id': true,
                            '$': index
                        },
                        'm1:QuestionAnswer': [
                            {
                                'm1:QuestionText': 'Date of Death',
                                'm1:YesNoCd': this.getDetail('dod')
                            },
                            {
                                'm1:QuestionText': 'Time of Death',
                                'm1:YesNoCd': this.getDetail('tod')
                            },
                            {
                                'm1:QuestionText': 'Cause of Death',
                                'm1:YesNoCd': this.getDetail('cod')
                            },
                            {
                                'm1:QuestionText': 'Was an autopsy performed',
                                'm1:YesNoCd': this.getDetail('autopsy')
                            }
                        ],
                    };
                    if (this.getDetail('autopsy') === 'no') {
                        accidentalDeath['m1:QuestionAnswer'].push(
                            {
                                'm1:QuestionText': 'If No, then please provide a reason',
                                'm1:YesNoCd': this.getDetail('reason')
                            }
                        );
                    }
                    setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m2:LossInfo'].push(accidentalDeath);
                } else if (claimType === 'lossProperty') {
                    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode || URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.THAILAND.countryCode) {
                        index++;
                        const damagedLossProperty = {
                            'm2:FeatureTypeCd': 'Damaged or Loss Property',
                            'm2:FeatureNumber': {
                                '@id': true,
                                '$': index
                            },
                            'm1:QuestionAnswer': [
                                {
                                    'm1:QuestionText': 'What was damaged or lost in the incident?',
                                    'm1:YesNoCd': this.getDetail('damagedLost')
                                },
                                {
                                    'm1:QuestionText': 'What is the claim amount?',
                                    'm1:YesNoCd': this.getDetail('claimAmount')
                                }
                            ]
                        };
                        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m2:LossInfo'].push(damagedLossProperty);
                    }
                }
            });
        }
        /* Loss Info ends */

        /* claims party Third party/witness details starts */
        if (this.getDetail('witnessInvolved') === 'yes') {
            let partyDataArray = [];
            partyDataArray = this.partyDataConstructor();
            let partyData;
            let index1 = 1;
            partyDataArray.forEach((party) => {
                index1++;
                partyData = {
                    'm1:ClaimsPartyInfo': {
                        'm1:ClaimsPartyRoleCd': party.witness
                    },
                    'm1:GeneralPartyInfo': {
                        'm2:CommunicationModeCd': '',
                        'm1:NameInfo': {
                            'm1:PersonName': {
                                'm1:GivenName': party.firstName,
                                'm1:Surname': party.lastName
                            }
                        },
                        'm1:Addr': {
                            'm1:Addr1': party.addressLine1,
                            'm1:Addr2': party.addressLine2,
                            'm1:City': party.city,
                            'm1:StateProvCd': '',
                            'm1:PostalCode': party.zipCode,
                            'm1:CountryCd': party.country,
                            'm1:StateProv': party.state
                        },
                        'm1:Communications': {
                            'm1:EmailInfo': {
                                'm1:EmailAddr': party.email
                            },
                            'm1:PhoneInfo': party.phoneData
                        },
                        'm1:MiscParty': {
                            'm1:MiscPartyInfo': {
                                '@id': index1,
                                'm1:MiscPartySubRoleCd': party.witness
                            }
                        },
                        'm2:IndOrOrgCd': ''
                    },
                    'm1:PersonInfo': {
                        'm1:GenderCd': '',
                        'm1:BirthDt': ''
                    }
                };
                setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'].push(partyData);
            });
        }
        /* claims party third party/witness details ends */

        /* claims party Dr address details starts */
        if (this.getDetail('claimType')) {
            const claimTypeArr = this.getDetail('claimType');
            if (claimTypeArr.indexOf('permanentDisability') !== -1) {
                const drData = {
                    'm1:ClaimsPartyInfo': {
                        'm1:ClaimsPartyRoleCd': 'Doctor'
                    },
                    'm1:GeneralPartyInfo': {
                        'm2:CommunicationModeCd': '',
                        'm1:NameInfo': {
                            'm1:PersonName': {
                                'm1:GivenName': this.getDetail('drFirstName'),
                                'm1:Surname': this.getDetail('drLastName')
                            }
                        },
                        'm1:Addr': {
                            'm1:Addr1': '',
                            'm1:Addr2': '',
                            'm1:City': '',
                            'm1:StateProvCd': '',
                            'm1:PostalCode': '',
                            'm1:CountryCd': '',
                            'm1:StateProv': '',
                        },
                        'm1:Communications': {
                            'm1:EmailInfo': {
                                'm1:EmailAddr': this.getDetail('emaildr'),
                            },
                            'm1:PhoneInfo': this.getDetail('drPhoneArray'),
                        },
                        'm1:MiscParty': {
                            'm1:MiscPartyInfo': {
                                '@id': this.getDetail('drIndex'),
                                'm1:MiscPartySubRoleCd': 'Doctor'
                            }
                        },
                        'm2:IndOrOrgCd': ''
                    },
                    'm1:PersonInfo': {
                        'm1:GenderCd': '',
                        'm1:BirthDt': ''
                    }
                };
                setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'].push(drData);
            }
        }
        /* claims party Dr address details ends */
        return setFnolModel;
    }

    resetClaimsPartyData() {
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'] = [];
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'] = [];
        setFnolModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsParty'].push(
            {
                'm1:ClaimsPartyInfo': {
                    'm1:ClaimsPartyRoleCd': 'Claimant'
                },
                'm1:GeneralPartyInfo': {
                    'm2:CommunicationModeCd': '',
                    'm1:NameInfo': {
                        'm1:PersonName': {
                            'm1:GivenName': '',
                            'm1:Surname': ''
                        }
                    },
                    'm1:Addr': {
                        'm1:Addr1': '',
                        'm1:Addr2': '',
                        'm1:City': '',
                        'm1:StateProvCd': '',
                        'm1:PostalCode': '',
                        'm1:CountryCd': '',
                        'm1:StateProv': ''
                    },
                    'm1:Communications': {
                        'm1:EmailInfo': {
                            'm1:EmailAddr': ''
                        },
                        'm1:PhoneInfo': [
                            {
                                'm1:PhoneTypeCd': '',
                                'm1:PhoneNumber': '',
                                'm1:CountryPhoneCd': ''
                            }
                        ]
                    },
                    'm1:MiscParty': {
                        'm1:MiscPartyInfo': {
                            '@id': 1,
                            'm1:MiscPartySubRoleCd': ''
                        }
                    },
                    'm2:IndOrOrgCd': ''
                },
                'm1:PersonInfo': {
                    'm1:GenderCd': '',
                    'm1:BirthDt': ''
                }
            }
        );
    }

    getDetail(requestName: string): any {
        if (requestName === 'sysDateGmt') {
                const systemDate = new Date();
                return CommonUtils.convertDateGMT(systemDate.toISOString(), 'YYYYMMDD');
        }
        if (requestName === 'currency') {
            this.countryData = CommonUtils.getCountryData(URL_PARAMETERS.COUNTRY.toLowerCase());
            if (this.countryData) {
                return this.countryData.currencyCode + '-' + this.countryData.currencyDescription;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'lob' || requestName === 'slob') {
            // if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
            //     const claimType = this.fnolModelUsService.getDetail('claimType');
            //     if (requestName === 'lob' && (claimType === 'Marine Cargo' || claimType === 'Inland Marine')) {
            //         return '11';
            //     } else if (requestName === 'slob' && (claimType === 'Marine Cargo' || claimType === 'Inland Marine')) {
            //         return '2';
            //     }
            // } else  if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode) {
            //   return '';
            // }
            //     else if (URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.NAM.countryCode && URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.SINGAPORENEW.countryCode) { 
            //     return requestName === 'lob' ? URL_PARAMETERS.LOB.toString() : '999'; 
            // } else if (URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.NAM.countryCode && URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORENEW.countryCode) { 
            //     return '5'; 
            // }
        }
        if (requestName === 'region') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return 'NAM';
            } else { 
                return 'APAC'; 
            }
        }
        if (requestName === 'coverageTypeCode') {
            return URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz ? (URL_PARAMETERS.TYPE === 'CAT' ? 'PD_CAT' : '') : '';
        }
        if (requestName === 'providerSysId') {
            return URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode ? 'CDP' : 'FNOL';
        }
        /* step 1 details */
        const claimantData = this._dataStoreService.get(FNOL_STEPS.CLAIMANT_INFO.name);
        if(claimantData){
        // if (requestName === 'policyNumber' && URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //     return this.fnolModelUsService.getDetail('policyNumber');
        // } else if (requestName === 'policyNumber' && URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.NAM.countryCode) {
        //     return URL_PARAMETERS.POLICY_NO.toString();
        // }
        if (requestName === 'identifyUs') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                if (claimantData.uiData.chooseYourself && claimantData.uiData.chooseYourself[0].name) {
                    return claimantData.uiData.chooseYourself[0].name;
                } else { 
                    return ''; 
                }
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                if (claimantData.uiData.submitting && claimantData.uiData.submitting.name) {
                    return claimantData.uiData.submitting.name;
                }
            } else {
                return claimantData.uiData.identifyUs;
            }
        }
        if (requestName === 'usCitizen') {
            if (claimantData.uiData.citizen) {
                return claimantData.uiData.citizen;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'extn') {
            if (claimantData.uiData.extension1) {
                return claimantData.uiData.extension1;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'idNumber') { 
            return claimantData.uiData.nric; 
        }
        if (requestName === 'firstName') {
            // if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode && 
            //     this.fnolModelUsService.getDetail('claimType') === 'Inland Marine') {
            //         const inlandIdentifier = this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name).uiData.inlandIdentify;
            //         return inlandIdentifier === 'Company' ? '' : this.fnolModelUsService.getDetail('inlandFirstName');
            // } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode && 
            //     this.fnolModelUsService.getDetail('claimType') === 'Marine Cargo') {
            //         const cargoIdentifier = this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name).uiData.cargoIdentify;
            //         return cargoIdentifier === 'Company' ? '' : this.fnolModelUsService.getDetail('cargoFirstName');
            // } else {
            //     return claimantData.uiData.firstName;
            // }
        }        
        if (requestName === 'lastName') {
            // if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode && 
            //     this.fnolModelUsService.getDetail('claimType') === 'Inland Marine') {
            //         const inlandIdentifier = this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name).uiData.inlandIdentify;
            //         return inlandIdentifier === 'Company' ? this.fnolModelUsService.getDetail('inlandCompanyName') : this.fnolModelUsService.getDetail('inlandLastName');
            // } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode && 
            //     this.fnolModelUsService.getDetail('claimType') === 'Marine Cargo') {
            //         const cargoIdentifier = this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name).uiData.cargoIdentify;
            //         return cargoIdentifier === 'Company' ? this.fnolModelUsService.getDetail('cargoCompanyName') : this.fnolModelUsService.getDetail('cargoLastName');                                        
            // } else {
            //     return claimantData.uiData.lastName;
            // }
        }
        if (requestName === 'phoneArray') {
            const phoneArray = [];
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
               // return this.fnolModelUsService.getDetail('phoneArrayStep3');
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                phoneArray.push(
                    {
                        'm1:PhoneTypeCd': 'Mobile Phone',
                        'm1:PhoneNumber': claimantData.uiData.contactPhone,
                        'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                    }
                );
                return phoneArray;
            } else {
                const mobileNo = claimantData.uiData.mobile;
                const phoneNo = claimantData.uiData.phoneNumber;
                if (mobileNo !== '' && mobileNo !== null) {
                    phoneArray.push(
                        {
                            'm1:PhoneTypeCd': 'Mobile Phone',
                            'm1:PhoneNumber': claimantData.uiData.mobile,
                            'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                        }
                    );
                }
                if (phoneNo !== '' && phoneNo !== null) {
                    phoneArray.push(
                        {
                            'm1:PhoneTypeCd': 'Home/Work Phone',
                            'm1:PhoneNumber': claimantData.uiData.phoneNumber,
                            'm1:PhoneExtn': claimantData.uiData.extension1 ? claimantData.uiData.extension1 : '',
                            'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                        }
                    );
                }
                return phoneArray;
            } 
        }
        // if (requestName === 'mail') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         if (this.fnolModelUsService.getDetail('claimType') === 'Inland Marine' && this.fnolModelUsService.getDetail('inlandEmail') !== '') {
        //             return this.fnolModelUsService.getDetail('inlandEmail');
        //         } else if (this.fnolModelUsService.getDetail('claimType') === 'Marine Cargo' && this.fnolModelUsService.getDetail('cargoEmail') !== '') {
        //             return this.fnolModelUsService.getDetail('cargoEmail');
        //         } else {
        //             return '';
        //         }
        //     } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
        //         return claimantData.uiData.contactEmail;
        //     } else {
        //         return claimantData.uiData.email;
        //     }
        // }
        // if (requestName === 'dob') {
        //     if (claimantData.uiData.dob) {
        //         return CommonUtils.convertDateGMT(claimantData.uiData.dob, 'YYYYMMDD');
        //     } else { 
        //         return ''; 
        //     }
        // }
        // if (requestName === 'currentAge') { 
        //     return claimantData.uiData.currentAge; 
        // }
        // if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode && requestName === 'title') { 
        //     return claimantData.uiData.title.name; 
        // }
        // if (requestName === 'address1') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('address1');
        //     } else {
        //         return claimantData.uiData.myAddressLookup;
        //     }
        // }
        // if (requestName === 'address2') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('address2');
        //     } else {
        //         return claimantData.uiData.addressLine2 ? claimantData.uiData.addressLine2: '';
        //     }
        // }
        // if (requestName === 'city') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('city');
        //     } else {
        //         return claimantData.uiData.city ? claimantData.uiData.city : '';
        //     }
        // }
        // if (requestName === 'state') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('state');
        //     } else {
        //         return claimantData.uiData.state ? claimantData.uiData.state : '';
        //     }
        // }
        // if (requestName === 'postalCode') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('postalCode');
        //     } else {
        //         return claimantData.uiData.postalCode ? claimantData.uiData.postalCode : '';
        //     }
        // }
        // if (requestName === 'countryCode') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('countryCode');;
        //     } else {
        //         return this._dataStoreService.getCountryCode();
        //     }
        // }
        // if (requestName === 'policyHolderName') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
        //         return claimantData.uiData.policyName;
        //     } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('policyHolderName')
        //     } else { 
        //         return ''; 
        //     }
        // }
        // if (requestName === 'policyEffectiveDate') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
        //         return CommonUtils.convertDateGMT(claimantData.uiData.fromDate, 'YYYYMMDD');
        //     } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('policyEffectiveDate');
        //     } else { 
        //         return ''; 
        //     }
        // }
        // if (requestName === 'policyExpiryDate') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
        //         return CommonUtils.convertDateGMT(claimantData.uiData.toDate, 'YYYYMMDD');
        //     } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('policyExpiryDate');
        //     } else { 
        //         return ''; 
        //     }
        // }
        // if (requestName === 'annualCode') {
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
        //         return this.fnolModelUsService.getDetail('annualCountryCode');
        //     } else { 
        //         return ''; 
        //     }
        // }
        // if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz || URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode 
        //     && requestName === 'brokerName' || requestName === 'brokerNumber' || requestName === 'brokerEmail') {
        //     if (requestName === 'brokerName') {
        //         if (claimantData.uiData.brokerName) {
        //             return claimantData.uiData.brokerName;
        //         } else { 
        //             return ''; 
        //         }
        //     } 
        //     if (requestName === 'brokerEmail') {
        //         if (claimantData.uiData.brokerEmail) {
        //             return claimantData.uiData.brokerEmail;
        //         } else { 
        //             return ''; 
        //         }
        //     }
        //     if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz && requestName === 'brokerNumber') {
        //         const brokerPhone = [];
        //         if (claimantData.uiData.brokerNumber) {
        //             brokerPhone.push(
        //                 {
        //                     'm1:PhoneTypeCd': 'Business',
        //                     'm1:PhoneNumber': claimantData.uiData.brokerNumber,
        //                     'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
        //                 }
        //             );
        //         }
        //         return brokerPhone;
        //     } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode && requestName === 'brokerNumber') {
        //         const brokerPhone = [];
        //         if (claimantData.uiData.brokerPrimary) {
        //             brokerPhone.push(
        //                 {
        //                     'm1:PhoneTypeCd': 'Primary Phone Number',
        //                     'm1:PhoneNumber': claimantData.uiData.brokerPrimary,
        //                     'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
        //                 }
        //             );
        //         }
        //         if (claimantData.uiData.brokerSecondary) {
        //             brokerPhone.push(
        //                 {
        //                     'm1:PhoneTypeCd': 'Secondary Phone Number',
        //                     'm1:PhoneNumber': claimantData.uiData.brokerSecondary,
        //                     'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
        //                 }
        //             );
        //         }
        //         return brokerPhone;
        //     }
        // }
    }
        /* step 2 details */
        const incidentData = this._dataStoreService.get(FNOL_STEPS.INCIDENT_DETAILS.name);
        const dateOfLoss = CommonUtils.convertDateGMT(incidentData?.uiData.dateLoss, 'YYYYMMDD');
    //     if(incidentData){
       
    //     if (requestName === 'lossDate') {
    //         if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
    //             if ((this.fnolModelUsService.getDetail('claimTypeCode') === 'MC') && 
    //                 (this.fnolModelUsService.getDetail('cargoLoss') === 'In Transit Loss')) {
    //                     return this.fnolModelUsService.getDetail('ladingShippingDate');
    //             } else {
    //                 return dateOfLoss;
    //             }
    //         } else {
    //             return CommonUtils.convertDateGMT(incidentData.uiData.tripIncidentDate, 'YYYYMMDD');
    //         }
    //     }
    //     if (requestName === 'lossTime') {
    //         if (incidentData && incidentData.uiData.tripIncidentTime) {
    //             const incidentTime = moment(incidentData.uiData.tripIncidentTime).format('hh:mm A');
    //             const incidentTimeArray = incidentTime.split(' ');
    //             if (incidentTimeArray[1] === 'AM') {
    //                 return incidentTimeArray[0] + ':0';
    //             } else { 
    //                 return incidentTimeArray[0] + ':1'; 
    //             }
    //         } else { 
    //             return ''; 
    //         }
    //     }
    //     if (requestName === 'estimateLoss' && URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
    //         return incidentData.uiData.estimateLoss;
    //     }
    //     if (requestName === 'lossDesc' && URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
    //         return incidentData.uiData.loss;
    //     }
    //     if (requestName === 'locationProp' && URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
    //         return incidentData.uiData.location;
    //     }
    //     if (requestName === 'incidentDesc') {
    //         if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
    //             return incidentData?.uiData.loss;
    //         } else if (incidentData.uiData.incident) {
    //             return incidentData.uiData.incident;
    //         } else { 
    //             return ''; 
    //         }
    //     }
    //     if (requestName === 'tripId') { 
    //         return incidentData.uiData.tripId; 
    //     }
    //     if (requestName === 'plateNo') { 
    //         return incidentData.uiData.vehicleNo; 
    //     }
    //     if (requestName === 'tripEndTime') { 
    //         return moment(incidentData.uiData.tripCompletionTime).format('hh:mm A'); 
    //     }
    //     if (requestName === 'jobAcceptTime') { 
    //         return moment(incidentData.uiData.jobAcceptanceTime).format('hh:mm A'); 
    //     }
    //     if (requestName === 'pickupTime') { 
    //         return moment(incidentData.uiData.tripCommencementTime).format('hh:mm A'); 
    //     }
    //     if (requestName === 'witnessInvolved') { 
    //         return incidentData.uiData.witnessInvolved; 
    //     }
    //     if (requestName === 'typeOfParty') {
    //         if (incidentData.uiData.typeParty) {
    //             return incidentData.uiData.typeParty.name;
    //         } else { 
    //             return ''; 
    //         }
    //     }
     
    // }
        /* step 3 details */
        const claimTypeData = this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name);
        if (claimTypeData) {
            if (requestName === 'claimType') {
                const claimTypeArray = [];
                if (claimTypeData.uiData.chooseClaimType) {
                    claimTypeData.uiData.chooseClaimType.forEach(element => {
                        claimTypeArray.push(element.name);
                    });
                }
                return claimTypeArray;
            }
            if (requestName === 'drVisit') {
                if (claimTypeData.uiData.visitDoctor) {
                    return CommonUtils.convertDateGMT(claimTypeData.uiData.visitDoctor, 'YYYYMMDD');
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'diagnosis') {
                if (claimTypeData.uiData.diagnosis.name !== 'Others') { 
                    return claimTypeData.uiData.diagnosis.name; 
                } else { 
                    return claimTypeData.uiData.others; 
                }
            }
            if (requestName === 'westernMedicine') {
                if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode || URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                    return parseInt(claimTypeData.uiData.medicalExpense['westernMedicine']);
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'dentalTreatment') {
                if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode || URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                    return parseInt(claimTypeData.uiData.medicalExpense['dentalTreatment']);
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'chineseMedicine' && URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                return parseInt(claimTypeData.uiData.medicalExpense['chineseMedicine']);
            }
            if (requestName === 'totalExpense') {
                if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode || URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                    return parseInt(claimTypeData.uiData.medicalExpense['sum']);
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'hospitalAdmitted' && URL_PARAMETERS.COUNTRY.toLowerCase() !== this.isCountryAuOrNz) {
                if (claimTypeData.uiData.admittedHospital) {
                    return claimTypeData.uiData.admittedHospital;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'featureTypeCd') {
                if (claimTypeData.uiData.admittedHospital && claimTypeData.uiData.admittedHospital === 'yes') { 
                    return 'MEH'; 
                } else { 
                    return 'ME'; 
                }
            }
            if (requestName === 'dateOfAdmission') {
                if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode) {
                    return CommonUtils.convertDateGMT(claimTypeData.uiData.dateAdmission, 'YYYYMMDD', 'zeroTime');
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'timeOfAdmission') {
                if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode) {
                    const hospitalizationTime = moment(claimTypeData.uiData.timeAdmission).format('hh:mm A');
                    const hospitalAdmTimeArray = hospitalizationTime.split(' ');
                    if (hospitalAdmTimeArray[1] === 'AM') { 
                        return hospitalAdmTimeArray[0] + ':0'; 
                    } else { 
                        return hospitalAdmTimeArray[0] + ':1'; 
                    }
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'hospitalizationDischarge') {
                if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode) {
                    return CommonUtils.convertDateGMT(claimTypeData.uiData.dateDischarge, 'YYYYMMDD', 'zeroTime');
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'dischargeTime') {
                if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode) {
                    const hospitalizationDischargeTime = moment(claimTypeData.uiData.timeDischarge).format('hh:mm A');
                    const hospitalDichargeTimeArray = hospitalizationDischargeTime.split(' ');
                    if (hospitalDichargeTimeArray[1] === 'AM') { 
                        return hospitalDichargeTimeArray[0] + ':0'; 
                    } else { 
                        return hospitalDichargeTimeArray[0] + ':1'; 
                    }
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'medicalleaveStartDate') {
                if (claimTypeData.uiData.startleave) {
                    return CommonUtils.convertDateGMT(claimTypeData.uiData.startleave, 'YYYYMMDD');
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'medicalleaveEndDate') {
                if (claimTypeData.uiData.endleave) {
                    return CommonUtils.convertDateGMT(claimTypeData.uiData.endleave, 'YYYYMMDD');
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'hospitalName') {
                if (claimTypeData.uiData.hospitalName !== null) { 
                    return claimTypeData.uiData.hospitalName; 
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'insuranceCover') {
                if (claimTypeData.uiData.insuranceCovering !== null) {
                    return claimTypeData.uiData.insuranceCovering;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'insuranceName') {
                if (claimTypeData.uiData.insuranceName !== null) {
                    return claimTypeData.uiData.insuranceName;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'claimFiled') {
                if (claimTypeData.uiData.filedClaim && claimTypeData.uiData.filedClaim !== 'Not Applicable') {
                    return claimTypeData.uiData.filedClaim;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'gotPayment') {
                if (claimTypeData.uiData.payment && claimTypeData.uiData.payment !== 'Not Applicable') {
                    return claimTypeData.uiData.payment;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'dod') {
                return CommonUtils.convertDateGMT(claimTypeData.uiData.dod, 'YYYYMMDD');
            }
            if (requestName === 'tod') { 
                return moment(claimTypeData.uiData.tod).format('hh:mm A'); 
            }
            if (requestName === 'cod') {
                if (claimTypeData.uiData.causeDeath.name !== 'Others') { 
                    return claimTypeData.uiData.causeDeath.name; 
                } else { 
                    return claimTypeData.uiData.others1; 
                }
            }
            if (requestName === 'clinic') {
                if (claimTypeData.uiData.clinic) {
                    return claimTypeData.uiData.clinic;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'hospitalAddress') {
                if (claimTypeData.uiData.hospitalAddress) {
                    return claimTypeData.uiData.hospitalAddress;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'hospitalAddress1') {
                if (claimTypeData.uiData.hospitalAddress1) {
                    return claimTypeData.uiData.hospitalAddress1;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'autopsy') { 
                return claimTypeData.uiData.autopsy; 
            }
            if (requestName === 'reason') { 
                return claimTypeData.uiData.reason; 
            }
            if (requestName === 'pdDate') {
                return CommonUtils.convertDateGMT(claimTypeData.uiData.dateDisability, 'YYYYMMDD');
            }
            if (requestName === 'pdType') {
                if (claimTypeData.uiData.permanent.name !== 'Others') { 
                    return claimTypeData.uiData.permanent.name; 
                } else { 
                    return claimTypeData.uiData.others2; 
                }
            }
            if (requestName === 'drFirstName') { 
                return claimTypeData.uiData.firstNameDoctor; 
            }
            if (requestName === 'drLastName') { 
                return claimTypeData.uiData.lastNameDoctor; 
            }
            if (requestName === 'drName') {
                return 'Dr ' + claimTypeData.uiData.firstNameDoctor + ' ' + claimTypeData.uiData.lastNameDoctor;
            }
            if (requestName === 'drPhoneArray') {
                const drPhoneArray = [];
                if (claimTypeData.uiData.mobileDoctor !== '' && claimTypeData.uiData.mobileDoctor !== null) {
                    drPhoneArray.push(
                        {
                            'm1:PhoneTypeCd': 'Mobile Phone',
                            'm1:PhoneNumber': claimTypeData.uiData.mobileDoctor,
                            'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                        }
                    );
                }
                if (claimTypeData.uiData.phoneNumberDoctor !== '' && claimTypeData.uiData.phoneNumberDoctor !== null) {
                    drPhoneArray.push(
                        {
                            'm1:PhoneTypeCd': 'Home/Work Phone',
                            'm1:PhoneNumber': claimTypeData.uiData.phoneNumberDoctor,
                            'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                        }
                    );
                }
                return drPhoneArray;
            }
            if (requestName === 'emaildr') {
                if (claimTypeData.uiData.emailDoctor) {
                    return claimTypeData.uiData.emailDoctor;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'drIndex') {
                if (this.allPartyData.length) {
                    return parseInt(this.allPartyData.length) + 2;
                } else { 
                    return 2; 
                }
            }
            if (requestName === 'damagedLost') {
                return claimTypeData.uiData.damageIncident.name;
            }
            if (requestName === 'claimAmount') {
                return claimTypeData.uiData.claimAmount;
            }
        }

        /* step 4 details */
        const paymentData = this._dataStoreService.get(FNOL_STEPS.PAYMENT_DETAILS.name);
        if(paymentData){
        if (requestName === 'payeeName') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                return paymentData.uiData.beneficiaryName;
            } else {
                return paymentData.uiData.payeeName;
            }
        }
        if (requestName === 'bankCode') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode || URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.THAILAND.countryCode) {
                return paymentData.uiData.bankCode;
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                return paymentData.uiData.swiftCode;
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.AUSTRALIA.countryCode) {
                return paymentData.uiData.bsb;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'bankName') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode) {
                return paymentData.uiData.bankTransfer;
            } else {
                return paymentData.uiData.bankName;
            }
        }
        if (requestName === 'branchCode') {
            if (paymentData.uiData.branchCode) {
                return paymentData.uiData.branchCode;
            } else { 
                return ''; 
            }
        }
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode && requestName === 'bankAddress') {
            if (paymentData.uiData.bankAddress) {
                return paymentData.uiData.bankAddress;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'paymentType' && (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode ||
            URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.THAILAND.countryCode)) {
            if (paymentData.uiData.paymentType) {
                return paymentData.uiData.paymentType;
            }
        }
        if (requestName === 'accountNumber') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                return paymentData.uiData.account;
            } else {
                return paymentData.uiData.accountNumber;
            }
        }

        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.AUSTRALIA.countryCode && requestName === 'gst' || requestName === 'abnNumber' || requestName === 'gstPolicy') {
            return paymentData.uiData[requestName] ? paymentData.uiData[requestName] : '';
        }
    }
        if (claimTypeData && URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz && requestName) {
            return claimTypeData.uiData[requestName] ? claimTypeData.uiData[requestName] : '';
        }
    }

    partyDataConstructor() {
        const incidentData = this._dataStoreService.get(FNOL_STEPS.INCIDENT_DETAILS.name);
        const incidentUIData = incidentData.uiData;
        const witnessData = [];
        for (let i = 0; i < 15; i++) {
            const newObj = {
                witness: '', email: '', firstName: '', lastName: '', witnessAddress: '', country: '',
                addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', mobile: '', phone: '', phoneData: [], extn: ''
            };
            Object.entries(incidentUIData).forEach(([key, value], index) => {
                if (value !== null) {
                    if (i === 0) {
                        if (key === 'typeParty') {
                            Object.entries(value).forEach(([key1, innerVal]) => {
                                if (key1 === 'name') {
                                    newObj.witness = innerVal;
                                }
                                if (key1 === 'en-name') {
                                    newObj.witness = innerVal;
                                }
                            });
                        } else if (key === 'emailWitness') { 
                            newObj.email = value.toString(); 
                        } else if (key === 'firstNameWitness') { 
                            newObj.firstName = value.toString(); 
                        } else if (key === 'lastNameWitness') { 
                            newObj.lastName = value.toString(); 
                        } else if (key === 'witnessAddress') { 
                            newObj.witnessAddress = value.toString(); 
                        } else if (key === 'country') { 
                            newObj.country = value.toString(); 
                        } else if (key === 'myAddressLookup') {
                            newObj.addressLine1 = value.toString();
                            // if (typeof value === 'string') {
                            //     newObj.addressLine1 = value;
                            // } else {
                            //     Object.entries(value).forEach(([innerKey, innerVal]) => {
                            //         if (innerKey === 'autoCompleteAddress') {
                            //             newObj.addressLine1 = innerVal;
                            //         }
                            //     });
                            // }
                        } else if (key === 'addressLine2') { 
                            newObj.addressLine2 = value.toString(); 
                        } else if (key === 'city') { 
                            newObj.city = value.toString(); 
                        } else if (key === 'state') { 
                            newObj.state = value.toString(); 
                        } else if (key === 'postalCode') { 
                            newObj.zipCode = value.toString(); 
                        } else if (key === 'mobileWitness') {
                            newObj.mobile = value.toString();
                            if (newObj.mobile && newObj.mobile !== '') {
                                newObj.phoneData.push(
                                    {
                                        'm1:PhoneTypeCd': 'Mobile Phone',
                                        'm1:PhoneNumber': newObj.mobile,
                                        'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                                    }
                                );
                            }
                        } else if (key === 'phoneNumberWitness') {
                            newObj.phone = value.toString();
                            if (newObj.phone && newObj.phone !== '') {
                                newObj.phoneData.push(
                                    {
                                        'm1:PhoneTypeCd': 'Home/Work Phone',
                                        'm1:PhoneNumber': newObj.phone,
                                        'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                                    }
                                );
                            }
                        } else if (key === 'extensionWitness') { 
                            newObj.extn = value.toString(); 
                        }
                    } else {
                        if (key === 'typeParty' + i) {
                            Object.entries(value).forEach(([innerKey, innerVal]) => {
                                if (innerKey === 'name') {
                                    newObj.witness = innerVal;
                                }
                                if (innerKey === 'en-name') {
                                    newObj.witness = innerVal;
                                }
                            });
                        } else if (key === 'emailWitness' + i) { 
                            newObj.email = value.toString(); 
                        } else if (key === 'firstNameWitness' + i) { 
                            newObj.firstName = value.toString(); 
                        } else if (key === 'lastNameWitness' + i) { 
                            newObj.lastName = value.toString(); 
                        } else if (key === 'witnessAddress' + i) { 
                            newObj.witnessAddress = value.toString(); 
                        } else if (key === 'country' + i) { 
                            newObj.country = value.toString(); 
                        } else if (key === 'myAddressLookup' + i) {
                            newObj.addressLine1 = value.toString();
                            // if (typeof value === 'string') {
                            //     newObj.addressLine1 = value;
                            // } else {
                            //     Object.entries(value).forEach(([innerKey, innerVal]) => {
                            //         if (innerKey === 'autoCompleteAddress') {
                            //             newObj.addressLine1 = innerVal;
                            //         }
                            //     });
                            // }
                        } else if (key === 'addressLine2' + i) { 
                            newObj.addressLine2 = value.toString(); 
                        } else if (key === 'city' + i) { 
                            newObj.city = value.toString(); 
                        } else if (key === 'state' + i) { 
                            newObj.state = value.toString(); 
                        } else if (key === 'postalCode' + i) { 
                            newObj.zipCode = value.toString(); 
                        } else if (key === 'mobileWitness' + i) {
                            newObj.mobile = value.toString();
                            if (newObj.mobile && newObj.mobile !== '') {
                                newObj.phoneData.push(
                                    {
                                        'm1:PhoneTypeCd': 'Mobile Phone',
                                        'm1:PhoneNumber': newObj.mobile,
                                        'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                                    }
                                );
                            }
                        } else if (key === 'phoneNumberWitness' + i) {
                            newObj.phone = value.toString();
                            if (newObj.phone && newObj.phone !== '') {
                                newObj.phoneData.push(
                                    {
                                        'm1:PhoneTypeCd': 'Home/Work Phone',
                                        'm1:PhoneNumber': newObj.phone,
                                        'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                                    }
                                );
                            }
                        } else if (key === 'extensionWitness' + i) { 
                            newObj.extn = value.toString(); 
                        }
                    }
                }
            });
            if (newObj?.firstName !== '') { 
                witnessData.push(newObj); 
            } else { 
                continue; 
            }
        }
        this.allPartyData = witnessData;
        return this.allPartyData;
    }
}
