import { filter } from 'rxjs/operators';
import {
  Component,
  ViewChild,
  Inject, Renderer2 
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { FnolService } from "./../features/fnol/services/fnol.service";
import { FNOL_COUNTRY } from "../features/fnol/config/fnol-constants";
import { AuthService } from "../auth/services";
import { CacheService } from "../shared/services/cache.service";
import { Okta } from "../shared/services/okta.service";
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "cdp-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  axisNavPanelClosed: boolean;
  navbarOpen: boolean;
  appAccountId: number = 1;
  appPanel: string;
  appPanelHighlighted: string;
  appRouteHome: string = "/index";
  appStep: number;
  appUserId: number = 1;

  axisNavbar: boolean = false;
  axisNavNotifications: boolean = true;
  axisNavUserName: boolean = true;

  axisNotification: any = {
    dismissible: true,
    fadeout: true,
    kind: "success",
    message: "See copydeck for text.",
    timeout: 2000,
    visible: false,
  };

  bodyTheme: string;
  dev: boolean = true;
  sub: any;
  subc: any;
  titlePage: string = "Document Title";
  titleProject: string = " :: Claims Digital Portal";
  step;
  Object;
  selectedLanguage: string | null;
  readonly FNOL_COUNTRY = FNOL_COUNTRY;
  fnolCheck = true;
  selectedItem = "myClaim";
  languages: string | undefined | null;
  country: string | undefined | null;
  @ViewChild("axisNotificationVar") axisNotificationVar;
  public axisNavbarRight: boolean = false;
  public claimDashboard: boolean = false;
  public circlePerson: boolean = true;
  public noNavBar: boolean = false;
  public axisNavbarRightPassword: boolean = false;
  isAuthenticated;
  public uaFormBooleanEx: FormGroup;
  language = localStorage.getItem("language");
  subList: any;
  cdpOrFnol = '';
  countries = {
    jp: true,
    sg: true,
    vn: true,
    th: true,
    [FNOL_COUNTRY.AUSTRALIA.countryCode]: true,
    [FNOL_COUNTRY.NEWZEALAND.countryCode]: true,
    us: true,
    singapore: true
  };
  partialRouterLink = '';
  showHeader: boolean = false;
  getCountryCode: any;
  SubjectStatus: any;
  title:any;
  constructor(
    private renderer: Renderer2,
    public auth: AuthService,
    private cacheService: CacheService,
    public router: Router,
    private fb: FormBuilder,
    public FnolService: FnolService,
    private okta: Okta,
    @Inject(OKTA_AUTH)
    public oktaAuth: OktaAuth
  ) {
    this.languages=   window.location.pathname.split("/")[4];
    this.country = localStorage.getItem("country")?.toLowerCase();
    if (window.location.href.indexOf("/fnol") > 0) {
      this.fnolCheck = true;
      this.selectedLanguage = "jp";
    } else {
      this.fnolCheck = false;
    }
    this.uaFormBooleanEx = this.fb.group({
      booleanControl: this.cacheService.get("language") || "en",
    });
  }

  ngOnInit(): void {
    // this.showHeader = this.FnolService.getPagestatus();
    // this.FnolService.pageStatus.subscribe((obj)=>{
    //       this.SubjectStatus =  obj.configValue
    //       const pagestat = window.location.pathname.split("/")[3]
    //       if((pagestat === "unavailable") || (pagestat === "undermaintenance")|| (this.SubjectStatus === "MAINTENANCE") || (this.SubjectStatus === "DOWNTIME")){
    //         this.showHeader = true
    //       }else{
    //         this.showHeader = false
    //       }
    // })
    this.FnolService.saveCounter = false;
    this.getCountryCode =window.location.pathname.includes('nordics')? window.location.pathname.split("/")[3] : window.location.pathname.split("/")[2];
    
    this.oktaAuth.authStateManager.subscribe((authState) => {
      this.isAuthenticated = authState.isAuthenticated;
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log('getting event url: ', event.url);
       if (window.location.href.indexOf("/cdp/jp/policies") > -1) {
         this.selectedItem = "myPolicy";
        } else if (
          window.location.href.indexOf("/cdp/jp/dashboard") > -1 
         ) {
         this.selectedItem = "myClaim";
       } else {
           this.selectedItem = "";
       }
      const segments = event.url.split('/');
      console.log('segments: ', segments);
      if (this.countries[segments[2]]) {
        this.partialRouterLink = `cdp/${segments[2]}`;
      } else {
        this.partialRouterLink = 'cdp/jp';
      }
    });

    // if(this.auth.isAuthenticationTokenAvailalbe()){
    //     this.registrationService.getUserData({ email: this.cacheService.get('usermail','COOKIE_STORAGE')}).subscribe(res =>{
    //         console.log(res);
    //         this.sharedService.userData.next(res);
    //     })
    // }
    this.FnolService.pageDetail.subscribe((res) => {
      this.step = res["step"];
    });
    // let languages= window.location.pathname.split('/')[4] === 'no'?"no":"en";
    if(this.languages === 'no'){
      this.title = 'Skademelding';
    } else {
      this.title = 'Claims Digital Portal';
    }

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe(); // with url query
    this.subc.unsubscribe();
  }
  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
    if (this.navbarOpen) {
      this.renderer.addClass(document.body, 'axis-navbar-mobile-open');
    }
    else {
      this.renderer.removeClass(document.body, 'axis-navbar-mobile-open');
    }
  }
  /* methods */
  closeOverlay(): void {
    this.navbarOpen = false;
    this.renderer.removeClass(document.body, 'axis-navbar-mobile-open');
  }

  signout() {
    this.closeOverlay();
    this.okta.logout();

  }

  // ngAfterViewInit(): void {
  //   if (this.cacheService.get("country") === "JP") {
  //     setTimeout(() => {
  //       this.uaFormBooleanEx
  //         .get("booleanControl")
  //         .patchValue(this.cacheService.get("language"));
  //     }, 0);
  //   }
  //   this.subList = this.router.events.subscribe((params) => {
  //     if (window.location.href.indexOf("/cdp/jp/policies") > 0) {
  //       this.selectedItem = "myPolicy";
  //     } else if (
  //       window.location.href.indexOf("/cdp/jp/dashboard") > 0 ||
  //       window.location.href.indexOf("/cdp/claim") > 0
  //     ) {
  //       this.selectedItem = "myClaim";
  //     } else {
  //       this.selectedItem = "";
  //     }
  //   });

  //   // or assign dynamically within component class

  //   //this.cd.detectChanges();
  // }
  // sidebar UI behaviors
  languageChange(e): void {
    const urlParams = new URLSearchParams(window.location.search);
    if (e && e !== (urlParams.get("language") as string).toLowerCase()) {
      window.open(
        `/fnol?country=${urlParams.get("country")}&policyNo=${urlParams.get(
          "policyNo"
        )}&language=${e}&lob=${urlParams.get("lob")}&partner=gojek`,
        "_self"
      );
      setTimeout(() => {
        this.selectedLanguage = urlParams.get("language");
      });
    }
  }
}
