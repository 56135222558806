import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from "@angular/router";
import { OktaCallbackComponent } from "@okta/okta-angular";
import { ThankYouComponent } from './fnol/success/thank-you/thank-you.component';
import { ServiceUnavailableComponent } from "./fnol/error/service-unavailable/service-unavailable.component";
import { MaintenancePageComponent } from "./fnol/error/maintenance-page/maintenance-page.component";
import { DataResolver } from "./fnol/services/data-resolver.service";
const routes: Routes = [
  // un-authenticated routes

  { path: "cdp/:country/unavailable", component: ServiceUnavailableComponent, resolve: { data: DataResolver } },
  { path: "cdp/:country/undermaintenance", component: MaintenancePageComponent, resolve: { data: DataResolver } },
  { path: "cdp/nordics/:country/:language/unavailable", component: ServiceUnavailableComponent, resolve: { data: DataResolver } },
  { path: "cdp/nordics/:country/:language/undermaintenance", component: MaintenancePageComponent, resolve: { data: DataResolver } },
  {
    path: "fnol",
    redirectTo: "cdp", pathMatch: "full"
  },
  {
    path: "cdp",
    loadChildren: () => import("./fnol/fnol.module").then((m) => m.FnolModule),
  },
  {
    path: "cdp-lite",
    loadChildren: () => import("./fnol/cdp-lite/cdp-lite/cdp-lite.module").then((m) => m.CdpLiteModule),
  },
  {
    path: "fnol/:country",
    redirectTo: "cdp/:country", pathMatch: "full"
  },
  {
    path: "cdp/:country", resolve: { data: DataResolver },
    loadChildren: () => import("./fnol/fnol.module").then((m) => m.FnolModule),
  },
  // { path: "login/:country", loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  // { path: "login/:country/:token", loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  // {
  //   path: "registration/jp",
  //   loadChildren: () =>
  //     import("./registration/registration.module").then(
  //       (m) => m.RegistrationModule
  //     ),
  // },
  { path: "login/callback", component: OktaCallbackComponent },
  { path: "success", component: ThankYouComponent },
  { path: ":country", redirectTo: "cdp", pathMatch: "full" },
  { path: "", redirectTo: "cdp-lite", pathMatch: "full" },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [ThankYouComponent, ServiceUnavailableComponent, MaintenancePageComponent],
  exports: [RouterModule],
})
export class FeaturesRoutingModule { }
