import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component } from "@angular/core";
import { AuthService } from "../auth/services/auth.service";
import { filter, map } from 'rxjs/operators';
import { Configuration } from "../../app/shared/configuration";
// import { CommonNordicsUtilsService } from "../../app/features/fnol/fnol-nordics/service/common-nordics-utils.service";
// import { landingPageLabels } from '../features/fnol/fnol-nordics/constants/fnol-norway-travel.constant';

@Component({
  selector: "cdp-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  fnolCheck = true;
  
  public languageList = [
    { src: 'assets/images/country/bv.png', label: 'NORWEGIAN' },
    { src: 'assets/images/country/gb.png', label: 'ENGLISH' }
  ];
  country: string | null | undefined =
    new URLSearchParams(window.location.search).get("country")?.toLowerCase() ||
    localStorage.getItem("country");
  selectedLanguage: string | undefined;
  language = "jp";
  followUp;
  aboutUs;
  contactus;
  workatAig;
  more;
  news;
  complaintHandling;
  support;
  recommSoftware;
  aigNorway;
  securit;
  legal;
  norway;

  constructor(public auth: AuthService, private router: Router,public config: Configuration) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.includes('fnol') || event.url.includes('cdp')) {
        this.fnolCheck = true;
        this.selectedLanguage = event.url.includes('nordics')? window.location.pathname.split("/")[4] : localStorage.getItem("language")?.toLowerCase();
        this.country =  event.url.includes('nordics')? window.location.pathname.split("/")[3]:localStorage.getItem("country")?.toLowerCase();
      } else {
        this.fnolCheck = false;
      }
    })
  }languageList1

  ngOnInit(): void {
    this.languageList1={ src:window.location.pathname.split('/')[4] === 'no'? "assets/images/country/bv.png" : "assets/images/country/gb.png", label: window.location.pathname.split('/')[4] === 'no'?"NORWEGIAN":"ENGLISH"}  
    if(this.languageList1.label === 'NORWEGIAN'){
      this.followUp = 'Følg oss';
      this.aboutUs = 'Om oss';
      this.contactus = 'Kontakt oss';
      this.workatAig = 'Jobb i AIG';
      this.more = 'Mer';
      this.news = 'Nyheter';
      this.support = 'Support';
      this.complaintHandling = 'Klagehåndtering';
      this.recommSoftware = 'Anbefalt programvare';
      this.aigNorway = 'AIG Norge';
      this.securit = 'Sikkerhet og personvern';
      this.legal = 'Juridisk informasjon';
      this.norway = 'Norge'
    }
    else {
      this.followUp = 'Follow Us';
      this.aboutUs = 'About Us';
      this.contactus = 'Contact Us';
      this.workatAig = 'Work at AIG';
      this.more = 'More';
      this.news = 'News';
      this.support = 'Support';
      this.complaintHandling = 'Complaint handling';
      this.recommSoftware = 'Recommended Software';
      this.aigNorway = 'AIG Norway';
      this.securit = 'Security and Privacy';
      this.legal = 'Legal Information';
      this.norway = 'Norway'
    }
   }
  modelChanged(e,i){
    console.log(e)
    if(e.label === 'NORWEGIAN'){
   window.location.assign(window.location.pathname.replace(window.location.pathname.split('/')[4],'no'))
    } else {
      window.location.assign(window.location.pathname.replace(window.location.pathname.split('/')[4]+'/'+window.location.pathname.split('/')[5],'en/'+window.location.pathname.split('/')[5]))
    }
  }
}
