<header>
  <ng-container *ngIf="!fnolCheck && !auth.countryCheck() && country !== 'singapore' 
                      && country !== 'us' && country !== 'au' && country !== 'nz' && !FnolService.getPagestatus()">
    <ng-container>
      <axis-navbar>
        <axis-navbar-logo>
          <img alt="AIG logo" src="assets/images/logos/aig-logo-blue.svg" />
          <span class="sr-only">American International Group, Inc.</span>
        </axis-navbar-logo>
        <axis-navbar-app-title>{{title}}</axis-navbar-app-title>
        <axis-navbar-right>
          <ul class="axis-nav">
            <li class="axis-navbar-item" *ngIf="axisNavbarRight">
              <button
                axis-button
                data-kind="link"
                (click)="modalConfirm.openModal('Dashboard')"
              >
                Dashboard
              </button>
            </li>
            <li class="axis-navbar-item" *ngIf="claimDashboard">
              <button
                axis-button
                data-kind="link"
                routerLink="/dashboard"
              >
                Dashboard
              </button>
            </li>
            <li class="axis-navbar-item" *ngIf="noNavBar">
              <axis-dropdown
                data-align="right"
                data-kind="icon"
                font="custom"
                icon="user_circle"
                templateClasses="axis-navbar-dropdown"
              >
                <axis-dropdown-list
                  *ngIf="circlePerson"
                  routerLink="/my-profile"
                  >My Profile
                </axis-dropdown-list>
                <axis-dropdown-list (click)="modalContact.openModal()">
                  <span>Contact Us</span>
                </axis-dropdown-list>
                <axis-dropdown-list
                  *ngIf="circlePerson"
                  routerLink="/login/logout"
                  >Sign Out
                </axis-dropdown-list>
                <axis-dropdown-list
                  *ngIf="axisNavbarRightPassword"
                  (click)="modalConfirm.openModal('SignOut')"
                  >Sign Out
                </axis-dropdown-list>
              </axis-dropdown>
            </li>
          </ul>
        </axis-navbar-right>
      </axis-navbar>
      <nav
        axisStickyNew="60"
        axisStickyScrollUp="true"
        class="axis-navbar-mobile"
        [ngClass]="{ open: navbarOpen }"
      >
        <div class="axis-mobile-overlay" (click)="clickOverlay()"></div>
        <div class="axis-mobile-header">
          <div class="axis-navbar-title">
            <axis-navbar-logo>
              <img alt="AIG logo" src="assets/images/logos/aig-logo-blue.svg" />
              <span class="sr-only">American International Group, Inc.</span>
            </axis-navbar-logo>
            <axis-navbar-app-title  *ngIf="languages === 'no'">Skademelding</axis-navbar-app-title>
            <axis-navbar-app-title  *ngIf="language !== 'jp' && languages !== 'no'">Claims Digital Portal</axis-navbar-app-title>
            <axis-navbar-app-title i18n="@@appTitle"  style="font-family: aig_meiryo;" *ngIf="language === 'jp'"
            >デジタル保険金請求</axis-navbar-app-title
          >
          </div>
          <!-- <button
        axis-button
        data-kind="icon"
        (click)="navbarOpen=!navbarOpen;saveStore('navbarOpen',navbarOpen)"
        title="Open/close navigation panel"
      >
        <axis-icon
          font="custom"
          icon="menu"
        ></axis-icon>
      </button> -->
          <button *ngIf="country === 'jp' "
            axis-button
            data-kind="link"
            (click)="
              navbarOpen = !navbarOpen; saveStore('navbarOpen', navbarOpen)
            "
          >
            <span class="_sr-only">Menu</span>
            <axis-icon icon="menu" font="custom"></axis-icon>
          </button>
        </div>
        <div
          class="axis-navbar-mobile-container"
          [ngClass]="{ open: navbarOpen }"
        >
          <!-- <div class="axis-mobile-header-open">
        <axis-navbar-logo>
          <a routerLink="/">
            <img
              alt="AIG logo"
              src="assets/images//logos/aig-logo.svg"
            >
            <span class="sr-only">American International Group, Inc.</span>
          </a>
        </axis-navbar-logo>
        <button
          axis-button
          data-kind="icon"
          (click)="navbarOpen=!navbarOpen"
        >
          <axis-icon
            data-icon="close"
            data-size="small"
          ></axis-icon>
          <span class="axis-btn-icon-text">Menu</span>
        </button>
      </div> -->
          <div class="axis-mobile-close-btn">
            <button
              axis-button
              data-kind="link"
              (click)="toggleNavbar()"
            >
              <span class="axis-btn-icon-text">Menu</span>
              <axis-icon icon="clear" font="custom"></axis-icon>
            </button>
          </div>
          <div class="axis-navbar-mobile-menu">
            <ul class="axis-list-group">
              <li class="axis-list-group-item" *ngIf="axisNavbarRight">
                <button
                  axis-button
                  data-kind="link"
                  (click)="modalConfirm.openModal('Dashboard')"
                >
                  Dashboard
                </button>
              </li>
              <li class="axis-list-group-item" *ngIf="claimDashboard">
                <button axis-button data-kind="link" routerLink="/dashboard">
                  Dashboard
                </button>
              </li>
              <li class="axis-list-group-item" *ngIf="circlePerson">
                <button axis-button data-kind="link" routerLink="/my-profile">
                  My Profile
                </button>
              </li>
              <li class="axis-list-group-item" *ngIf="circlePerson">
                <button axis-button data-kind="link" routerLink="/login/logout">
                  Sign Out
                </button>
              </li>
              <li class="axis-list-group-item" *ngIf="axisNavbarRightPassword">
                <button
                  axis-button
                  data-kind="link"
                  (click)="modalConfirm.openModal('SignOut')"
                >
                  Sign Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="auth.countryCheck() && !FnolService.getPagestatus() && this.isAuthenticated">
    <axis-navbar>
      <axis-navbar-logo>
        <img alt="AIG logo" src="assets/images/logos/aig-logo-blue.svg" />
        <span class="sr-only">American International Group, Inc.</span>
      </axis-navbar-logo>
      <axis-navbar-app-title i18n="@@appTitle"  style="font-family: aig_meiryo;"
        >デジタル保険金請求</axis-navbar-app-title
      >
      <axis-navbar-right *ngIf="this.isAuthenticated">
        <ul class="axis-nav">
          <li
            class="axis-navbar-item"
            [ngClass]="{ active: selectedItem === 'myClaim' }"
          >
            <button style="font-family: aig_meiryo;"
              axis-button
              i18n="@@myClaim"
              data-kind="link"
              [routerLink]="[partialRouterLink, 'dashboard']"
            >
            お客さまのご請求内容
            </button>
          </li>
          <li
            class="axis-navbar-item"
            [ngClass]="{ active: selectedItem === 'myPolicy' }"
          >
            <button style="font-family: aig_meiryo;"
              axis-button
              i18n="@@myPolicy"
              data-kind="link"
              [routerLink]="[partialRouterLink, 'policies']"
            >
            お客さまのご契約内容
            </button>
          </li>
          <!--  <li
            class="axis-navbar-item">
            <form [formGroup]="uaFormBooleanEx" >
              <axis-button-toggle
              data-size="small"
              #smallBtn

             formControlName="booleanControl"
            >
              <axis-button-toggle-label  (click)="clickToggle('en')"  [value]="'en'">EN</axis-button-toggle-label>
              <axis-button-toggle-label (click)="clickToggle('jp')" [value]="'jp'">JP</axis-button-toggle-label>

            </axis-button-toggle>
          </form>
          </li> -->

          <li class="axis-navbar-item">
            <axis-dropdown
              data-align="right"
              data-kind="icon"
              font="custom"
              icon="user_circle"
              templateClasses="axis-navbar-dropdown"
            >
              <axis-dropdown-list  style="font-family: aig_meiryo;" [routerLink]="[partialRouterLink, 'profile']"
                >お客さま情報
              </axis-dropdown-list>
              <axis-dropdown-list  style="font-family: aig_meiryo;" (click)="signout()"
                >サインアウト
              </axis-dropdown-list>
            </axis-dropdown>
          </li>
        </ul>
      </axis-navbar-right>
    </axis-navbar>
    <nav
      axisStickyNew="60"
      axisStickyScrollUp="true"
      class="axis-navbar-mobile"
      [ngClass]="{ open: navbarOpen }"
    >
      <div class="axis-mobile-overlay" (click)="clickOverlay()"></div>
      <div class="axis-mobile-header">
        <div class="axis-navbar-title">
          <axis-navbar-logo>
            <img alt="AIG logo" src="assets/images/logos/aig-logo-blue.svg" />
            <span class="sr-only">American International Group, Inc.</span>
          </axis-navbar-logo>
          <axis-navbar-app-title i18n="@@appTitle"   style="font-family: aig_meiryo;" *ngIf="language === 'jp'"
            >デジタル保険金請求</axis-navbar-app-title
          >
          <axis-navbar-app-title style="font-family: aig_meiryo;"  i18n="@@appTitle" *ngIf="language !== 'jp'"
            >デジタル保険金請求</axis-navbar-app-title
          >
        </div>
        <!-- <button
          axis-button
          data-kind="icon"
          (click)="navbarOpen=!navbarOpen;saveStore('navbarOpen',navbarOpen)"
          title="Open/close navigation panel"
        >
          <axis-icon
            font="custom"
            icon="menu"
          ></axis-icon>
        </button> -->
        <button
          axis-button
          data-kind="link"
          *ngIf="this.isAuthenticated"
          (click)="
            navbarOpen = !navbarOpen; saveStore('navbarOpen', navbarOpen)
          "
        >
          <span class="_sr-only">Menu</span>
          <axis-icon icon="menu" font="custom"></axis-icon>
        </button>
      </div>
      <div
        class="axis-navbar-mobile-container"
        [ngClass]="{ open: navbarOpen }"
      >
        <!-- <div class="axis-mobile-header-open">
          <axis-navbar-logo>
            <a routerLink="/">
              <img
                alt="AIG logo"
                src="assets/images//logos/aig-logo.svg"
              >
              <span class="sr-only">American International Group, Inc.</span>
            </a>
          </axis-navbar-logo>
          <button
            axis-button
            data-kind="icon"
            (click)="navbarOpen=!navbarOpen"
          >
            <axis-icon
              data-icon="close"
              data-size="small"
            ></axis-icon>
            <span class="axis-btn-icon-text">Menu</span>
          </button>
        </div> -->
        <div class="axis-mobile-close-btn">
          <button
            axis-button
            data-kind="link"
            (click)="toggleNavbar()"
          >
            <span class="axis-btn-icon-text"></span>
            <axis-icon icon="clear" font="custom"></axis-icon>
          </button>
        </div>
        <div class="axis-navbar-mobile-menu">
          <ul class="axis-list-group">
            <li class="axis-list-group-item">
              <button
                axis-button
                (click)="closeOverlay()"
                i18n="@@myClaim" style="font-family: aig_meiryo;"
                data-kind="link"
                [routerLink]="[partialRouterLink,'dashboard']"
              >
              お客さまのご請求内容
              </button>
              <!-- <button
                axis-button
                i18n="@@myClaim"
                *ngIf="language !== 'jp'"
                data-kind="link"
                routerLink="/cdp/dashboard"
              >
                My Claims
              </button> -->
            </li>
            <li class="axis-list-group-item">
              <button
                axis-button style="font-family: aig_meiryo;"
                i18n="@@myPolicy"
                (click)="closeOverlay()"
                data-kind="link"
                [routerLink]="[partialRouterLink, 'policies']"
              >
              お客さまのご契約内容
              </button>
              <!-- <button
                axis-button
                i18n="@@myPolicy"
                data-kind="link"
                routerLink="/cdp/policies"
                *ngIf="language !== 'jp'"
              >
                My Policy
              </button> -->
            </li>
            <li class="axis-list-group-item" *ngIf="axisNavbarRight">
              <button
                axis-button
                data-kind="link"
                (click)="modalConfirm.openModal('Dashboard')"
              >
                Dashboard
              </button>
            </li>
            <li class="axis-list-group-item" *ngIf="claimDashboard">
              <button axis-button data-kind="link" routerLink="/dashboard">
                Dashboard
              </button>
            </li>
            <li
              class="axis-list-group-item"
              *ngIf="circlePerson"
            >
              <button axis-button data-kind="link" 
              (click)="closeOverlay()"
              [routerLink]="[partialRouterLink, 'profile']" 
              style="font-family: aig_meiryo;">
                お客さま情報
              </button>
            </li>
            <!-- <li
              class="axis-list-group-item"
              *ngIf="circlePerson && language !== 'jp'"
            >
              <button axis-button data-kind="link" routerLink="cdp/profile">
                My Profile
              </button>
            </li> -->

            <!-- <li
              class="axis-list-group-item"
              *ngIf="circlePerson && language !== 'jp'"
            >
              <button axis-button data-kind="link" (click)="signout()">
                Sign Out
              </button>
            </li> -->
            <li
              class="axis-list-group-item"
              *ngIf="circlePerson"
            >
              <button axis-button data-kind="link" (click)="signout()" style="font-family: aig_meiryo;">
                サインアウト
              </button>
            </li>
            <li class="axis-list-group-item" *ngIf="axisNavbarRightPassword">
              <button
                axis-button
                data-kind="link"
                (click)="modalConfirm.openModal('SignOut')"
              >
                Sign Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </ng-container>
  <ng-container *ngIf="fnolCheck && !auth.countryCheck() && !FnolService.getPagestatus()">
    <axis-navbar
      [ngClass]="{ singapore_nav_header: country === 'singapore' }"
    >
      <axis-navbar-logo>
        <img alt="AIG logo" src="assets/images/logos/aig-logo-blue.svg" />
        <span class="sr-only">American International Group, Inc.</span>
      </axis-navbar-logo>
      <axis-navbar-app-title *ngIf="country !== 'singapore'"
        >Claims Digital Portal</axis-navbar-app-title
      >
      <axis-navbar-right>
        <ul class="axis-nav">
          <li
            *ngIf="
              step &&
              step['current'] &&
              step['current']['slNo'] === 1 &&
              (country === 'th' || country === 'vn')
            "
            class="axis-navbar-item _border-right _flex-row-reverse"
          >
            <axis-button-toggle
              data-size="small"
              #smallBtn
              (getSelectedValue)="languageChange($event)"
              [(ngModel)]="selectedLanguage"
            >
              <axis-button-toggle-label [active]="true" [value]="'en'"
                >EN</axis-button-toggle-label
              >
              <axis-button-toggle-label *ngIf="country === 'vn'" [value]="'vn'"
                >VN</axis-button-toggle-label
              >
              <axis-button-toggle-label *ngIf="country === 'th'" [value]="'th'"
                >TH</axis-button-toggle-label
              >
            </axis-button-toggle>
          </li>
          <!-- Temporarily removing in hopes that we can reactivate gojek
        <li class="axis-navbar-item" *ngIf="country === 'sg'">
          <img
            alt="Gojek logo"
            class="axis-img-responsive"
            src="assets/images/logos/gojek.png"
            templateClasses="axis-navbar-dropdown"
            width="125px"
          >
        </li> -->
        </ul>
      </axis-navbar-right>
    </axis-navbar>
    <nav
      axisStickyNew="60"
      axisStickyScrollUp="true"
      class="axis-navbar-mobile"
      [ngClass]="{
        open: navbarOpen,
        singapore_nav_header: country === 'singapore'
      }"
    >
      <div class="axis-mobile-overlay" (click)="clickOverlay()"></div>
      <div
        class="axis-mobile-header"
        [ngClass]="{ singapore_nav_header: country === 'singapore' }"
      >
        <div class="axis-navbar-title">
          <axis-navbar-logo>
            <img alt="AIG logo" src="assets/images/logos/aig-logo-blue.svg" />
            <span class="sr-only">American International Group, Inc.</span>
          </axis-navbar-logo>
          <axis-navbar-app-title *ngIf="country !== 'singapore' || country !== 'us'"
            >Claims Digital Portal</axis-navbar-app-title
          >
          <axis-navbar-right>
            <ul class="axis-nav">
              <li class="axis-navbar-item" *ngIf="country === 'sg'">
                <img
                  alt="Gojek logo"
                  class="axis-img-responsive"
                  src="assets/images/logos/gojek.png"
                  templateClasses="axis-navbar-dropdown"
                  width="80px"
                />
              </li>
            </ul>
          </axis-navbar-right>
        </div>
      </div>
      <div
        class="axis-navbar-mobile-container"
        [ngClass]="{ open: navbarOpen }"
      ></div>
    </nav>
  </ng-container>

  <ng-container *ngIf="FnolService.getPagestatus()">
    <ng-container>
      <axis-navbar>
        <axis-navbar-logo>
          <img alt="AIG logo" src="assets/images/logos/aig-logo-blue.svg" />
          <span class="sr-only">American International Group, Inc.</span>
        </axis-navbar-logo>
        <axis-navbar-app-title  *ngIf="languages === 'no'">Skademelding</axis-navbar-app-title>
        <axis-navbar-app-title *ngIf="getCountryCode !== 'jp'">Claims Digital Portal</axis-navbar-app-title>
        <axis-navbar-app-title i18n="@@appTitle" style="font-family: aig_meiryo;"
          *ngIf="getCountryCode === 'jp'">デジタル保険金請求</axis-navbar-app-title>
      </axis-navbar>
      <nav axisStickyNew="60" axisStickyScrollUp="true" class="axis-navbar-mobile">
        <div class="axis-mobile-header">
          <div class="axis-navbar-title">
            <axis-navbar-logo>
              <img alt="AIG logo" src="assets/images/logos/aig-logo-blue.svg" />
              <span class="sr-only">American International Group, Inc.</span>
            </axis-navbar-logo>
            <axis-navbar-app-title  *ngIf="languages === 'no'">Skademelding</axis-navbar-app-title>
            <axis-navbar-app-title *ngIf="getCountryCode !== 'jp'">Claims Digital Portal</axis-navbar-app-title>
            <axis-navbar-app-title i18n="@@appTitle" style="font-family: aig_meiryo;"
              *ngIf="getCountryCode === 'jp'">デジタル保険金請求</axis-navbar-app-title>
          </div>
        </div>
      </nav>
    </ng-container>
  </ng-container>
</header>
<modal-confirm #modalConfirm></modal-confirm>
<modal-contact #modalContact></modal-contact>
