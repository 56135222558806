<footer class="axis-footer">

  <div
    class="axis-content-wrapper"
  >
     
   
  <div class="axis-container-fluid">
    <ul class="axis-list-inline">
      <li><a  href="https://www.aig.sg/terms-of-use"
        target="_blank">Terms of Use</a></li>
      <li><a  href="https://www.aig.sg/privacy"
        target="_blank">Privacy</a></li>
      <li><a href="https://www.aig.sg/legal-notice"
        target="_blank">Legal Notice</a></li>
      <li><a  href="https://www.aig.sg/aboutus"
        target="_blank">aig.com</a></li>
    </ul>
    <p class="axis-footer-copyright">
      Copyright &#169; 2024 American International Group, Inc. All rights reserved.
    </p>
    <p class="axis-footer-copyright">
      American International Group, Inc. (AIG) is a leading global insurance organization. AIG member companies offer
      insurance solutions that help businesses and individuals in approximately 70 countries and jurisdictions protect
      their assets and manage risks. AIG common stock is listed on the New York Stock Exchange.
    </p>
  </div>
 
    
  </div>

</footer>
<!-- <axis-scroll2tb></axis-scroll2tb> -->