import { Observable, Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { EncrDecrService } from "././services/utils/encr-decr.service";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from './config';
import { Router } from '@angular/router';
import { apiEndPoints } from './services/utils/apiEndPointConstants';

@Injectable()
export class Configuration {
  response: any;
  public buildVersion: string = "0.1";
  public hostName: string;

  public googleMapsAPIEndpoint: string;
  public googleMapsAPIKey: string;
  public googleRecapchaSiteKey: string;

  public mClientId: string;
  public cClientId: string;

  public userEmailID: string;

  // APAC ANH change start
  public aigSharedeMailID: any;
  public brokerEmailID: string;
  public applicationUserId: string;
  // APAC ANH change end

  public spId: string;

  public cognitoLoginUrl: string;
  public cognitoLogoutUrl: string;

  public smTargetUrl: string;
  public smLoginUrl: string;

  public smLogoutUrl: string;
  public smChangePasswordUrl: string;

  public baseURL: any;
  public baseURLJP: any;
  public baseURLFNOL: any;
  public apiEndPoints: any = {};
  public apiEndPoints_cdp_lite: any ={};
  public authorizationKey: string;
  public authorizationKey_new: string;
  public emailId: string;

  public searchPolicyHeaderEmail: string;

  public aem: string;
  private configSub: Observable<any>;
  public siteId : string;
  public maintanenceSubject = new Subject();
  constructor(private EncrDecr: EncrDecrService, private http: HttpClient) {
    const url = AppConfig.getConfigUrl();
    console.log('ConfigUrl..', url);
    this.configSub = this.http.get(url);
    /*
    if (location.hostname.includes("dev.cdpfnol.nprd.aig.com")) {
      this.configSub = this.http.get("assets/config/config.json");
    } else if (location.hostname.includes("qa2.cdpfnol.nprd.aig.com")) {
      this.configSub = this.http.get("assets/config/config-qa.json");
    } else if (location.hostname.includes("uat.cdpfnol.nprd.aig.com")) {
      this.configSub = this.http.get("assets/config/config-model.json");
    } else if (location.hostname.includes("cdpfnol.aig.com")) {
      this.configSub = this.http.get("assets/config/config-prod.json");
    } else {
      this.configSub = this.http.get("assets/config/config.json");
    } */

  }

  initConfig() {
    const url = AppConfig.getConfigUrl();
    console.log("Calling config again");
    console.log('ConfigUrl...', url);
    this.configSub = this.http.get(url);

    /*
    if (location.hostname.includes("dev.cdpfnol.nprd.aig.com")) {
      this.configSub = this.http.get("assets/config/config.json");
    } else if (location.hostname.includes("qa2.cdpfnol.nprd.aig.com")) {
      this.configSub = this.http.get("assets/config/config-qa.json");
    } else if (location.hostname.includes("uat.cdpfnol.nprd.aig.com")) {
      this.configSub = this.http.get("assets/config/config-model.json");
    } else if (location.hostname.includes("cdpfnol.aig.com")) {
      this.configSub = this.http.get("assets/config/config-prod.json");
    } else {
      this.configSub = this.http.get("assets/config/config.json");
    }
    */
    this.configSub.subscribe((response) => {
      this.hostName = location.hostname;
      this.googleMapsAPIEndpoint = response.googleMapsAPIEndpoint;
      this.googleMapsAPIKey = this.EncrDecr.dataEncryptDecrypt(
        response.googleMapsAPIKey,
        "decrypt"
      );
      this.googleRecapchaSiteKey = this.EncrDecr.dataEncryptDecrypt(
        response.googleRecapchaSiteKey,
        "decrypt"
      );

      this.mClientId = this.EncrDecr.dataEncryptDecrypt(
        response.mClientId,
        "decrypt"
      );
      this.cClientId = response.cClientId_new;

      this.userEmailID = response.userEmailID;
      // APAC ANH change start
      this.aigSharedeMailID = response.aigSharedeMailID;
      this.brokerEmailID = response.brokerEmailID;
      this.applicationUserId = response.applicationUserId;
      // APAC ANH change end

      this.spId = this.EncrDecr.dataEncryptDecrypt(response.spId, "decrypt");

      this.cognitoLoginUrl = response.cognitoLoginUrl;
      this.cognitoLogoutUrl = response.cognitoLogoutUrl;

      this.smTargetUrl = response.smTargetUrl;
      this.smLoginUrl = response.smLoginUrl;

      this.smLogoutUrl = response.smLogoutUrl;
      this.smChangePasswordUrl = response.smChangePasswordUrl;
      this.baseURLJP = response.baseURLJP;
      this.baseURL = response.baseURL;
      this.baseURLFNOL = response.baseURLFNOL;
      this.apiEndPoints = response.apiEndPoints;
      this.apiEndPoints_cdp_lite = response.apiEndPoints_cdp_lite;
      this.authorizationKey = this.EncrDecr.dataEncryptDecrypt(
        response.authorizationKey,
        "decrypt"
      );
      this.authorizationKey_new = response.authorizationKey_new;
      this.emailId = "vinod.biradar@aig.com";

      this.searchPolicyHeaderEmail = response.searchPolicyHeaderEmail;
      this.aem = response.analyticsURL;
      this.siteId = response.siteId;
      this.callMaintanenceApi();
    });
    
  }
  callMaintanenceApi() {

    this.maintanenceSubject.next('checkMaintanenceApiStatus');
  }
}
