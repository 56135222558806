export const cdpLite= 
{
	"applicationContext": {
		"countryCode": "SG",
		"language": "en",
		"lineOfBusiness": "ACHE",
		"claimTypeCd": "",
		"subLineOfBusiness": "999",
		"userId": "Claims Digital Portal"
	},
	"claimsDetails": {
		"userId": "Claims Digital Portal",
		"dateofIncident": "20220207T050000.000 GMT",
		"claimNumber": "9015753092SG",
		"content": ""
	},
	"Icorr": {
		"iCorrespondence": {
			"requestHeader": {
				"userID": "O1111147",
				"lineOfBusiness": "ACHE",
				"requestApplicationID": "FNOL",
				"requestMessageID": "9015753092SG",
				"language": "en",
				"countryCode": "SG",
				"systemDate": "20230309"
			},
			"ApplicationContext": {
				"applicationID": "EXTPOR-001",
				"country": "SG",
				"language": "en"
			},
			"correspondenceItem": {
				"pdfGenerationData": {
					"correspondenceItemID": "FNOL_AH",
					"claimDetails": {
						"claimID": "9015753092SG",
						"cdpDetails": {
							"info": [
								{
									"title": "Claimant Information",
                                    "attribute":[]
								},
								
								{
									"title": "Claim Details",
									"attribute":[]
								},
								{
									"title": "Payment Details",
									"attribute": "S8ows+7MvuJHF7Dybw7YrFxQc5l6l8sMvkf8JA2NehzdXG3jWEIbuSZPaRGp9yEIklCN6hO8aTpaVQ8/oxaRq8QzYrOi57vvof5RWi5mZ4dO+GiRKcSb1wRB2P8iwvXGJnauDtGcNbk3zG62hjDCv7YozgQhNfnfBFTHcPZdq+q4d8/JzOWNmHyYagR98K9ev9xApcH+d7L1iWEPq8+6J5j4T6JIle69ww21sZkhbQo="
								},
								{
									"title": "Upload Supporting Documents",
									"attribute":[]
								}
								// {
								// 	"title": "Review and Submit the Claim",
								// 	"attribute": [
								// 		{
								// 			"name": "I have read and agree to the declarations and authorisation & by submitting this form, I have read and agree with the declarations and authorisation",
								// 			"value": "Yes"
								// 		}
								// 	]
								// }
							]
						}
					}
				},
				"distributionGenerationData": {
					"insuredID": "",
					"clientName": "",
					"notificationType": "",
					"country": "SG",
					"lob": "ACHE",
					"lossState": "",
					"lossAddressID": ""
				},
				"correspondenceGenerationData": {
					"callerDistributionFax": "",
					"callerDistributionEMAIL": "",
					"claimantEmail": "",
					"brokerEmail": "",
					"sharedServiceEmail": "",
					"isNotificationClaim": "",
					"lossState": "",
					"reportedByFirstName": "",
					"reportedByLastName": "",
					"mobile": "",
					"businessPhone": "",
					"originKey": "",
					"dateReported": "",
					"lossDate": "20220207T050000.000 GMT",
					"insuredName": "",
					"clientName": "AIG",
					"policyNumber": "",
					"batchName": "AIG 09/03/23 5:39:48PM"
				}
			}
		}
	}
}
