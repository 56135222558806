import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/shared/configuration';
import { DataStoreService } from '../../../services/data-store.service';
import { FnolModelService } from '../../../services/fnol-model.service';
import { FormatAddressService } from '../../../services/format-address.service';
import { FNOL_STEPS_LITE } from '../../../config/fnol-sg-constants';
import * as moment from 'moment';
import { CommonUtils } from '../../../shared/utils/common-utils';
import{cdpLite} from './cdp-lite-model'
import { URL_PARAMETERS } from '../../../config/urlParameters.constant';
import { CHECKBOXHEADINGLITETravel, CHECKBOXLITEBAG, CHECKBOXLITEINJURED, CHECKBOXLITEPERSONAL, FNOL_COUNTRY } from '../../../config/fnol-constants';
import { Utils } from 'axis-ui-generator';
import { AxisInputNumberPipe } from 'axis-components';

@Injectable({
  providedIn: 'root'
})
export class CdpLiteModelService {
  personalAccident =[];
  permanantdiablity;
  leavebenifit;
  otherClaimShow;
  accidentalDeatBoolean;
  foundmedical
    policyDetails;
    phoneCodeList;
    insuredDetails = null;
    step2 = 'fnol-claimtype-details';
  step1Name ='fnol-claimtype-details'
  step1 ='fnol-claimant-info'
  personalDetails =[]
  hsotDrInfo= []
  data
  uiStructure: any;
  uiData: any;
  mySettings51: any = {
    view_roundoff: false,
    thousands_separator: ",",
    decimal_separator: ".",
    fraction_size: "2",
    reg_exp: "^\d{1,3}(,\d{3})*(\.\d{2})?$",
    decimal: "",
    restrict: false
   };
    constructor(
      private config: Configuration,
      private dataStoreService: DataStoreService,
      private fnolModelService: FnolModelService,
      private formatAddress: FormatAddressService,
      public axisPipe : AxisInputNumberPipe
    ) {
      
    }
  
    setPolicyHolderDetails(details: any) {
      this.insuredDetails = {
        policyNo: details["policyNumber"],
        firstName: details["firstName"] || details["policyFirstName"],
        lastName: details["lastName"] || details["policyLastName"],
        dob: details["dateOfBirth"] || details["policyDateOfBirth"],
        nric: details["nricFin"] || details["policyNricFin"],
      };
    }
  
    getPrivacyPolicySectionInfo() {
      return [
        {
          name: "Privacy Policy:",
          value: "Yes",
        },
        {
          name: "Terms of Use:",
          value: "Yes",
        },
        {
          name: "By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use:",
          value: "Yes",
        },
      ];
    }
    getClaimTypeData(){

      if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel'){
        return this.getTravelDetails(this.step2)
      }
      else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other'){
        return this.getOtherDetails()
      }

      else if(this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident'){
        return this.personalAccidantPayload()
      }
    }
    convertDateGMT(datePassed, dateFormat, zeroCheck?): string {
      const dateFormatted = moment(datePassed).format(dateFormat);
      if (zeroCheck && zeroCheck === 'zeroTime') {
        return dateFormatted + 'T000000.000 GMT';
      } else { return dateFormatted + 'T050000.000 GMT'; }
    }
    getLossDate(){

      if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident'){
        const lossDate = this.convertDateGMT(
          this.dataStoreService.get(this.step2).uiData["accidentDate"],
          "YYYYMMDD"
        );
        return lossDate;
      }
      else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel'){
        const lossDate = this.convertDateGMT(
          this.dataStoreService.get(this.step2).uiData["dateSymptom"],
          "YYYYMMDD"
        );
        return lossDate;
      }

      else if(this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other'){
        const lossDate = this.convertDateGMT(
          new Date().toString(),
          "YYYYMMDD"
        );
        return lossDate;
      }
    }
    getLossDocDate(){
      if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident'){
        return this.dataStoreService.get(this.step2).uiData["accidentDate"]
      }
      else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel'){
        return this.dataStoreService.get(this.step2).uiData["dateSymptom"]
      }

      else if(this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other'){
        const currentDate = new Date().toString()
        return currentDate
      }
    }
   
    getDetails(requestName: string){
       if (requestName === "policyNo") {
        if (this.policyDetails?.response?.policyDetails?.externalReference) {
          return this.policyDetails.response.policyDetails.externalReference;
        } 
        // else if (claimantData.uiData['enteredPolicyNumber']) {
        //   return 'NOMATCHPOLICY';
        // } 
        else {
          return 'NOPOLICY';
        }
      } 
    }
    getFnolRequestBody(
      type: string = "",
      policyDetailsData: any = {},
      phoneCodeListData: any = []
    ) {
      this.policyDetails = policyDetailsData;
      this.phoneCodeList = phoneCodeListData;
  
      const requestObj = [];
      cdpLite["applicationContext"]["userId"] =
        this.config.userEmailID;
        cdpLite["claimsDetails"]["userId"] = this.config.userEmailID;
        cdpLite["claimsDetails"]["claimNumber"] =
        this.fnolModelService.getClaimNumber();
        cdpLite["claimsDetails"]["dateofIncident"] =
        this.getLossDate();
        cdpLite.Icorr.iCorrespondence.requestHeader.requestMessageID= this.fnolModelService.getClaimNumber();
        cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.claimID = this.fnolModelService.getClaimNumber()
     // requestObj.push(commonSetFnolSgModel);
  
      if (
        (type === "setFnol")
      ) {
        
        return cdpLite;
      }
  
      // if (
      //   type === "finalSubmit" &&
      //   URL_PARAMETERS.COUNTRY.toLowerCase() ===
      //     FNOL_COUNTRY.LITE.countryCode
      // ) {
      //   requestObj.push(this.generateIcorModelData());
      // }
      let finalValue=this.prepDataForPDF(this.step1);
      if (URL_PARAMETERS.COUNTRY.toLowerCase() ===
         FNOL_COUNTRY.LITE.countryCode) {
          console.log("inside payload function starting")
         cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[0].attribute=finalValue
         cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[1].attribute=this.getClaimTypeData();
         cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[2].attribute=this.getPaymentData('fnol-payment-details');
         cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[3].attribute=this.getUploadDetails('fnol-upload-docs');
         cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.claimantEmail =this.dataStoreService.get("fnol-claimant-info").uiData['email'];
         cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.sharedServiceEmail= this.config.aigSharedeMailID
         ? this.config.aigSharedeMailID["sg"]
         : this.config.aigSharedeMailID;
         cdpLite.applicationContext.claimTypeCd = this.dataStoreService.get(this.step2).uiData["claimBenefits"];
         cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.mobile =this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode'].hasOwnProperty('label')?this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode']['label']+ "-"  + this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneNumber'] : this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode'] + "-"  + this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneNumber'];
         cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByFirstName =this.dataStoreService.get("fnol-claimant-info").uiData['firstName'];
         cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByLastName =this.dataStoreService.get("fnol-claimant-info").uiData['lastName'];
         cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.insuredName =this.dataStoreService.get("fnol-claimant-info").uiData['firstName'] + " "+this.dataStoreService.get("fnol-claimant-info").uiData['lastName'];
         cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.policyNumber =this.dataStoreService.get("fnol-claimant-info").uiData['policyNumber'];

      }
  
      return cdpLite;
    }
  
    decimalConverter(num: string, locale: string): string {
      let convert2Num: number = parseFloat(num);
      let decimalNum = convert2Num.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      if (decimalNum.substring(decimalNum.length-3)[0] === ',') {
        let decimalNumber = decimalNum.replace(decimalNum.substring(decimalNum.length-3)[0], '.');
        return decimalNumber;
      } else {
        return decimalNum;
      }
    }
  
    getData(name, type, stepName) {
      let resultData
      this.uiStructure = this.dataStoreService.get(stepName).uiStructure;
      this.uiData = this.dataStoreService.get(stepName).uiData;
      const checkBoxFlag = Utils.getFieldFromStructure(this.uiStructure, name);
      if (type == "label") {
        try{resultData = Utils.getFieldFromStructure(this.uiStructure, name);
        }
        
        catch(error){
          resultData =''
        }
        if(!resultData){
          return  resultData ='';
         }
        if(resultData?.type === "checkbox"){
            return {
               key:resultData?.heading,
               value: resultData?.questionCd,
            }
        }
        return resultData;
      } 
      else if(type === "rpa"){
        const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
        if(resultData?.type === "dropdown"){
        if(resultData.hasOwnProperty('isMultiSearchSelect')  ){
           return this.uiData[name].map(i=>i.id).join(',')
          } else if( !resultData.hasOwnProperty('isMultiSearchSelect')){
           return this.uiData[name]?.id
          }
        } else if(resultData?.type === "radio"){    
         
          return  this.uiData[name];
        } 
         else if(resultData?.type === "checkbox"){    
         
          return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No' ) ? "Yes" : "No"
             
         }
         
        else if(resultData?.type === "text" ||  resultData?.type === "datepicker" ||resultData?.type === "timepicker" || resultData?.type === null || resultData?.type === "textarea") {
          return null
        }
  
      } else {
       // let language= window.location.pathname.split('/')[4];
      //  if(language  === "en"){
        const resultData = Utils.getFieldFromStructure(this.uiStructure, name);
        if(resultData?.type === "datepicker" &&  this.uiData[name] != 'Invalid date' &&  this.uiData[name] != null &&  this.uiData[name] != ''){
          return (this.uiData[name]!=null && this.uiData[name]!="") ? moment(new Date(this.uiData[name])).format('DD-MM-YYYY'):this.uiData[name];
        }else if(resultData?.type === "timepicker"){
         return (this.uiData[name]!=null && this.uiData[name]!="") &&this.uiData[name]?.length>5  ? moment(this.uiData[name]).format("hh:mm A"):this.uiData[name];
        }else if(resultData?.type === "checkbox"){
          return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No' ) ? $localize`:@@yesNor:Yes` : $localize`:@@noNor:No`
         }
                else if(resultData?.type === "dropdown"){
       
          if(resultData.hasOwnProperty('isMultiSearchSelect')  ){
            return this.uiData[name].map(i=>i.name).join(',')
           } else if( !resultData.hasOwnProperty('isMultiSearchSelect')){
            return this.uiData[name]?.name
           }
          
         } else if(resultData?.type === "radio"){    
         // let language= window.location.pathname.split('/')[4]; 
           //return language === 'en'? this.uiData[name]: Utils.getFieldFromStructure(this.uiStructure, name).options.filter(e => e.value===this.uiData[name])[0]?Utils.getFieldFromStructure(this.uiStructure, name).options.filter(e => e.value===this.uiData[name])[0]['label']:""
           return  this.uiData[name];
        }
        else{
          return this.uiData[name]
        }
  
       }
    }
    checkCustom( field,index,step){
      if( this.getData(field + index, "label", step)['type']==="custom" &&
      this.getData(field + index, "label", step)['element']==="CurrencyDropdownComponent"
      ){
        return this.getData(field + index , "", step)['phoneNumber']?
        this.getData(field + index , "", step)['phoneCode'].hasOwnProperty('label')?
        this.getData(field + index , "", step)['phoneCode']['label']+ " "  + this.axisPipe.transform(this.getData(field + index , "", step)['phoneNumber'].toString(),this.mySettings51):
        this.getData(field + index , "", step)['phoneCode'] + " "  + this.axisPipe.transform(this.getData(field + index , "", step)['phoneNumber'].toString(),this.mySettings51)
         : null
      }
      else {
        return this.getData(field + index, "", step)  
      }
    }

    prepDataForPDF(step){
      let personalInfo = ["heading","policyNumber", "groupName","identificationNumber","firstName","lastName","dob","currentAge"];
      let uiData = this.dataStoreService.get(step).uiData;
      let personalDetailsInfo = [];
      personalInfo.forEach(res => {
        
          if(res=="heading"){
            personalDetailsInfo.push(
              {
                name: 'Personal Information',
                value: 'heading'
              })
          }
          else {
            personalDetailsInfo.push(
              {
                name: this.getData(res, "label", step)['label'],
                value: this.getData(res, "", step)
              })
          }
      })
      let contactInfo =["heading","email","contactNumber","personalCountry","myAddressLookup","levelUnitNumber","postalCode"]
      contactInfo.forEach(res => {
        
        if(res=="heading"){
          personalDetailsInfo.push(
            {
              name: 'Contact Information',
              value: 'heading'
            })
        }
        else {
          if(res === 'contactNumber' && this.uiData["contactNumber"]!==null){
            personalDetailsInfo.push(
              {
                name: 'Contact Number',
                value: this.getData("contactNumber", "", step)['phoneCode'].hasOwnProperty('label')? this.getData("contactNumber", "", step)['phoneCode']['label']+ "-"  + this.getData("contactNumber", "", step)['phoneNumber'] : this.getData("contactNumber", "", step)['phoneCode'] + "-"  + this.getData("contactNumber", "", step)['phoneNumber']
              })
          }
          else if(res==="personalCountry" && this.getData(res, "label", step)['type'] ==="dropdown"){
            personalDetailsInfo.push({
              name: this.getData('personalCountry', "label", step)['label'],
              value: this.dataStoreService.get('fnol-claimant-info').uiData['personalCountry'].name
            })
          }
          else if(res=== 'levelUnitNumber' && this.uiData['levelUnitNumber'] !== ""){
            personalDetailsInfo.push(
              {
                name: this.getData(res, "label", step)['label'],
                value: this.getData(res, "", step)
              })
          }
          else{
           if(res!== 'levelUnitNumber'){
          personalDetailsInfo.push(
            {
              name: this.getData(res, "label", step)['label'],
              value: this.getData(res, "", step)
            })
          }
          } 
        }
    })
     
      console.log(personalDetailsInfo,"personal details")
      return personalDetailsInfo
    }
    
    getClaimType(step){
      let claimType =["claimBenefits"]
      let claimTypeInfo=[]
      claimType.forEach(fieldName => {
          
        claimTypeInfo.push(
            {
              name: this.getData(fieldName, "label", step)['label'],
              value: this.getData(fieldName, "", step)
            })
          
        
    })
     return claimTypeInfo;
    }
    getTravelDetails(step){
      let travelDetailsInfo =[];
      travelDetailsInfo.push(
        {
          name: this.getData('claimBenefits', "label", step)['label'] === "Select Claim Type"?"What is the cause of our claim?":"",
          value: this.dataStoreService.get(step).uiData["claimBenefits"]
        })
      
      let travelInfo =["heading","dateSymptom","dateVisit","diagnosisIllness"]
     
      let uiData = this.dataStoreService.get(step).uiData;
      travelInfo.forEach(fieldName => {
        
        if(fieldName=="heading"){
          travelDetailsInfo.push(
            {
              name: 'Travel Details',
              value: 'heading'
            })
        }
        else {
          if(fieldName==="diagnosisIllness" && fieldName.indexOf('diagnosisIllness')>-1){
          travelDetailsInfo.push(
            {
              name: this.getData('diagnosisIllness', "label", step)['label'],
              value: this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisIllness']?.name
            })
          }
          else{
            travelDetailsInfo.push(
              {
                name: this.getData(fieldName, "label", step)['label'],
                value: this.getData(fieldName, "", step)
              })
          }
        }
    })
    let travelBenefit =["heading","chooseClaimTypeIllness"]
    travelBenefit.forEach(fieldName => {
        
      if(fieldName=="heading"){
        travelDetailsInfo.push(
          {
            name: 'Travel Benefits',
            value: 'heading'
          })
      }
      else {
        const newArray=[];
        this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].forEach(element => {
          if(CHECKBOXHEADINGLITETravel.find(field=>field.key===element.name)!=undefined){
            let headingValue =CHECKBOXHEADINGLITETravel.find(field=>field.key===element.name).heading;
            newArray.push(headingValue);
          }
          // newArray.push(element.name);
        });
          travelDetailsInfo.push(
            {
              name: this.getData(fieldName, "label", step)['label'],
              value: newArray.join(', ')
            })
      }
  })
  if (this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x=>x.name  === 'claimingIncident')!=undefined)
  {
    let travelBenefitType =["heading","injuredSick"]
    travelBenefitType.forEach(fieldName => {
        
      if(fieldName=="heading"){
        travelDetailsInfo.push(
          {
            name: 'Medical Incident',
            value: 'heading'
          })
      }
      else {
        travelDetailsInfo.push(
          {
            name: this.getData(fieldName, "label", step)['label'],
            value: this.dataStoreService.get('fnol-claimtype-details').uiData['injuredSick'] === 'wasInjured'?'I was injured':'I was sick',
          }
        )
      }
  })
  if (uiData['injuredSick'] === "wasInjured" && uiData['injuredSick'] !==null) {
    console.log(uiData['injuredSick'],"uidata of checkbox")
    let insurance = ["claimDescribeWords", "whenOccur","diagnosisInjured","medicalExpenseInjured","travelAccident"];
    insurance.forEach(fieldName => {
      if(fieldName === "medicalExpenseInjured"){
        let medicalDetails = ["heading","westernMedicine","mobileAdd","dentalTreatment",,'sum']
        medicalDetails.forEach(key => {
          if(key ==="heading"){
            travelDetailsInfo.push(
              {
              name: "Cost of Treatment",
              value :"heading"
              })
          }
          else{
            //if(this.dataStoreService.get(this.step2)?.uiData["medicalExpenseInjured"]){
              //if(costInfo){
              travelDetailsInfo.push(
                {
                  name: key === "westernMedicine" ?'Medical Treatment' : key ===  "mobileAdd" ? 'Mobility Aid': key ===  "dentalTreatment" ? 'Dental Treatment' : key ===  "sum"? 'Total Claim Amount':'',
                  value: this.dataStoreService.get(this.step2).uiData["medicalExpenseInjured"][key] ? "SGD" +" "+this.decimalConverter(this.dataStoreService.get(this.step2).uiData["medicalExpenseInjured"][key],'SG') :"SGD 0.00"
                  //this.dataStoreService.get(this.step2).uiData["medicalExpense"][key]? "SGD" +" "+this.dataStoreService.get(this.step2).uiData["medicalExpense"][key] :"SGD 0.00"
                  //"SGD" +" "+ this.dataStoreService.get(this.step2).uiData["medicalExpense"][key]? this.dataStoreService.get(this.step2).uiData["medicalExpense"][key] :0
                 // this.checkCustom('nameCost',"",step)
                  //this.decimalConverter(this.getMedicineTreatmentCopy("Western medicine"), 'SG')
                  }
            )
          //}
          }    
        })
      }
      if(fieldName === "diagnosisInjured"){
        if(fieldName.indexOf('diagnosisInjured') > -1){
          travelDetailsInfo.push(
            {
             name: this.getData('diagnosisInjured', "label", step)['label'],
             value: this.getData('diagnosisInjured', "", step),
           })
        }
        if(this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisInjured'].id === '8'){
        travelDetailsInfo.push(
          {
           name: this.getData('diagnosisOther', "label", step)['label'],
           value: this.getData('diagnosisOther', "", step),
         })
       }
      }
      if (fieldName === "travelAccident"){
         if(uiData['travelAccident'] === 'Yes' && uiData['travelAccident'] !==null){
          //let travelAccidentDetails =['claimDescribe','nameCost']
          //travelAccidentDetails.forEach(fieldName =>{
             travelDetailsInfo.push(
             {
              name: this.getData('travelAccident', "label", step)['label'],
              value: this.getData('travelAccident', "", step),
            }, 
            {
              name: this.getData('claimDescribe', "label", step)['label'],
              value: this.getData('claimDescribe', "", step),
            },
            {
              name: this.getData('nameCost', "label", step)['label'],
              value: this.checkCustom('nameCost',"",step),
            })
         // })
         }
         else {
          travelDetailsInfo.push({
            name: this.getData('travelAccident', "label", step)['label'],
           value: this.getData('travelAccident', "", step),
         })
         }
      }
  
      else {
        if(fieldName !=='medicalExpenseInjured' && fieldName !=='diagnosisInjured'){
       travelDetailsInfo.push({
        name: this.getData(fieldName, "label", step)['label'],
        value: this.getData(fieldName, "", step),
      
    })
  }
  }
  })
  
  
  }
  else if (uiData['injuredSick'] === "wasSick" && uiData['injuredSick'] !==null){
    let insurance = ["claimDescribeWordsSick", "whenOccurSick","diagnosisSickInjuredSick","medicalExpenseSick","travelAccidentSick"];
    insurance.forEach(fieldName => {
      if(fieldName === "medicalExpenseSick"){
        let medicalDetails = ["heading","westernMedicine","mobileAdd","dentalTreatment",'sum']
        medicalDetails.forEach(key => {
          if(key ==="heading"){
            travelDetailsInfo.push(
              {
              name: "Cost of Treatment",
              value :"heading"
              })
          }
          else{
              travelDetailsInfo.push(
                {
                  name: key ===  "westernMedicine" ?'Medical Treatment': key ===  "mobileAdd" ? 'Mobility Aid': key ===  "dentalTreatment" ? 'Dental Treatment' :  key ===  "sum"? 'Total Claim Amount':'',
                 // value: "SGD" +" "+ this.decimalConverter(this.dataStoreService.get(this.step2).uiData["medicalExpenseSick"][key],'SG')
                  value: this.dataStoreService.get(this.step2).uiData["medicalExpenseSick"][key]? "SGD" +" "+this.decimalConverter(this.dataStoreService.get(this.step2).uiData["medicalExpenseSick"][key],'SG') :"SGD 0.00"
                  
                  }
            )
          }    
        })
      }
      if(fieldName === "diagnosisSickInjuredSick"){
        if(fieldName.indexOf('diagnosisSickInjuredSick') > -1){
          travelDetailsInfo.push(
            {
             name: this.getData('diagnosisSickInjuredSick', "label", step)['label'],
             value: this.getData('diagnosisSickInjuredSick', "", step),
           })
        }
        if(this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisSickInjuredSick'].id === '13'){
        travelDetailsInfo.push(
          {
           name: this.getData('diagnosisSickOtherSick', "label", step)['label'],
           value: this.getData('diagnosisSickOtherSick', "", step),
         })
       }
      }
      if (fieldName === "travelAccidentSick"){
         if(uiData['travelAccidentSick'] === 'Yes' && uiData['travelAccidentSick'] !==null){
          
             travelDetailsInfo.push(
             {
              name: this.getData('travelAccidentSick', "label", step)['label'],
              value: this.getData('travelAccidentSick', "", step),
            }, 
            {
              name: this.getData('claimDescribeSick', "label", step)['label'],
              value: this.getData('claimDescribeSick', "", step),
            },
            {
              name: this.getData('nameCostSick', "label", step)['label'],
              value: this.checkCustom('nameCostSick',"",step),
            })
         }
         else {
          travelDetailsInfo.push({
            name: this.getData('travelAccidentSick', "label", step)['label'],
           value: this.getData('travelAccidentSick', "", step),
         })
         }
      }
  
      else {
        if(fieldName !=='medicalExpenseSick' && fieldName !=='diagnosisSickInjuredSick'){
       travelDetailsInfo.push({
        name: this.getData(fieldName, "label", step)['label'],
        value: this.getData(fieldName, "", step),
      
    })
  }
  }
  })
  }
}
 if(this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x=>x.name  === 'claimingDelayed') !== undefined){
  let travelBenefitType =["heading","originalFlightNumber","originalArriveDate","originalArriveTime","revisedFlightNumber","actualArriveDate","actualArriveTime","flightDelayed"]
  travelBenefitType.forEach(fieldName => {
        
    if(fieldName=="heading"){
      travelDetailsInfo.push(
        {
          name: 'Delayed Flight',
          value: 'heading'
        })
    }
    else {
      if(fieldName === "flightDelayed"){
        if(fieldName.indexOf('flightDelayed') > -1){
          travelDetailsInfo.push(
            {
             name: this.getData('flightDelayed', "label", step)['label'],
             value: this.getData('flightDelayed', "", step),
           })
        }
        if(this.dataStoreService.get('fnol-claimtype-details').uiData['flightDelayed'].id === '7'){
        travelDetailsInfo.push(
          {
           name: this.getData('provideDetailsTravel', "label", step)['label'],
           value: this.getData('provideDetailsTravel', "", step),
         })
       }
      }
      else {
        travelDetailsInfo.push(
          {
            name: this.getData(fieldName, "label", step)['label'],
            value: this.getData(fieldName, "", step),
          }
        )
      }
    }
})
 }
 if(this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x=>x.name  === 'claimingTrip') !== undefined){
  let travelBenefitType =["heading","diagnosisSickTripSick","claimingForOther","dateOfCancellation","flightExpense","flightRefund","accommodationExpense","accommodationRefund","otherTransportationExpense","otherTransportationRefund"]
  travelBenefitType.forEach(fieldName => {
        
    if(fieldName=="heading"){
      travelDetailsInfo.push(
        {
          name: 'Cancelled Trip',
          value: 'heading'
        })
    }
    else {
      if(fieldName ==="flightExpense" || fieldName ==="flightRefund" || fieldName ==="accommodationExpense" || fieldName ==="accommodationRefund" || fieldName ==="otherTransportationExpense" || fieldName ==="otherTransportationRefund"){
        travelDetailsInfo.push(
          {
            name: this.getData(fieldName, "label", step)['label'],
            value: this.checkCustom(fieldName,"",step)
          }
        )
      }
      if(fieldName === "diagnosisSickTripSick"){
        if(fieldName.indexOf('diagnosisSickTripSick') > -1){
          travelDetailsInfo.push(
            {
             name: this.getData('diagnosisSickTripSick', "label", step)['label'],
             value: this.getData('diagnosisSickTripSick', "", step),
           })
        }
        if(this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisSickTripSick'].id === '7'){
        travelDetailsInfo.push(
          {
           name: this.getData('diagnosisTripOtherSick', "label", step)['label'],
           value: this.getData('diagnosisTripOtherSick', "", step),
         })
       }
       if(this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosisSickTripSick'].id === '2'){
        travelDetailsInfo.push(
          {
           name: this.getData('diagnosisSickTripShip', "label", step)['label'],
           value: this.getData('diagnosisSickTripShip', "", step),
         })
       }
      }
      if(fieldName === "claimingForOther" || fieldName === "dateOfCancellation"){
      travelDetailsInfo.push(
        {
          name: this.getData(fieldName, "label", step)['label'],
          value: this.getData(fieldName, "", step),
        }
      )
      }
    }
})
 }
 if(this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x=>x.name  === 'claimingLost') !== undefined){
  let travelBenefitType =["heading","itemClaimType"]
  travelBenefitType.forEach(fieldName => {
        
    if(fieldName=="heading"){
      travelDetailsInfo.push(
        {
          name: 'Baggage / Item Claim',
          value: 'heading'
        })
    }
    
      else {
      const newArray=[];
      this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'].forEach(element => {
        if(CHECKBOXLITEBAG.find(field=>field.key===element.name)!=undefined){
          let headingValue =CHECKBOXLITEBAG.find(field=>field.key===element.name).heading;
          newArray.push(headingValue);
        }
      
      });
      travelDetailsInfo.push(
        {
          name: this.getData(fieldName, "label", step)['label'],
          value: newArray.toString(),
        }
      )
    }
  })
  let travelBagsDetails = ['travelBags']
  travelBagsDetails.forEach(fieldName =>{
  if(fieldName === 'travelBags'){
    travelDetailsInfo.push(
      {
        name: this.getData('travelBags', "label", step)['label'],
        value: this.getData('travelBags', "", step),
      }
    )
  if(uiData['travelBags'] ==="Delayed"){
    let delayedDetails =['landDate','timeLand','returnedDate','timeReturned']
    delayedDetails.forEach(fieldName => {
      travelDetailsInfo.push(
        {
          name: this.getData(fieldName, "label", step)['label'],
          value: this.getData(fieldName, "", step),
        }
      )
    })
   }

   if(uiData['travelBags'] ==="Damaged"){
    let delayedDetails =['bagsTripSick','damagedDate','provideDetails',"heading"]
    delayedDetails.forEach(fieldName => {
      if(fieldName ==="heading"){
        travelDetailsInfo.push(
          {
            name: 'Bags and/or Items Details',
            value: 'heading'
          })
      }
      else{
      travelDetailsInfo.push(
        {
          name: this.getData(fieldName, "label", step)['label'],
          value: this.getData(fieldName, "", step),
        }
      )
      }
    })
  
    if(this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name ==="myBags" || ((this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name ==="myBags" && this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][1]?.name ==="myItems") || (this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][1]?.name ==="myBags" && this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name ==="myItems"))){
      let bagDamage = Utils.getFieldFromStructure(this.dataStoreService.get(step).uiStructure, "addItemsMain").fields

    let travelMainArr = []
    bagDamage.forEach((data, i) => {
      let travelArr=[]
      const index = i==0 ? "" : i;
      const labelIndex = index!=""?(index-1):index;
      const matches = data.name.match(/(\d+)/);
     const index1 = matches != null ? matches[0] :"";
      const bagDamageDetails = ["bagItem", "nameValue", "yearPurchased","whatDamaged","repairCost","heading"];
      bagDamageDetails.forEach((item, i) => {
         if(item === "nameValue" || item === "repairCost"){
          travelDetailsInfo.push(
            {
              name: this.getData(item + index1, "label", step)['label'],
              value: this.checkCustom(item + index1,"",step)
            }
          )
        }
        else if(item === "whatDamaged"){
            travelDetailsInfo.push(
              {
               name: this.getData('whatDamaged' + index1, "label", step)['label'],
               value: this.getData('whatDamaged' + index1, "", step),
             })     
          if(this.dataStoreService.get('fnol-claimtype-details').uiData['whatDamaged'+index1]?.id === '5'){
          travelDetailsInfo.push(
            {
             name: this.getData('provideDetailsDamaged' + index1, "label", step)['label'],
             value: this.getData('provideDetailsDamaged' + index1, "", step),
           })
         }
        }
        else if (item ==="heading"){
          travelDetailsInfo.push(
            {
              name: "",
              value: "heading"
            }
          )
        }
        else{
          travelDetailsInfo.push(
            {
              name: this.getData(item + index1, "label", step)['label'],
              value: this.getData(item + index1, "", step)
            }
          )
        }

      });
    })
  }
  //  else if((this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name ==="myBags" && this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][1]?.name ==="myItems") || (this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][1]?.name ==="myBags" && this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name ==="myItems")){
  //     let bagDamage = Utils.getFieldFromStructure(this.dataStoreService.get(step).uiStructure, "addItemsMain").fields

  //   let travelMainArr = []
  //   bagDamage.forEach((data, i) => {
  //     let travelArr=[]
  //     const index = i==0 ? "" : i;
  //     const labelIndex = index!=""?(index-1):index;
  //     const matches = data.name.match(/(\d+)/);
  //    const index1 = matches != null ? matches[0] :"";
  //     const bagDamageDetails = ["bagItem", "nameValue", "yearPurchased","whatDamaged","repairCost","heading"];
  //     bagDamageDetails.forEach((item, i) => {
  //        if(item === "nameValue" || item === "repairCost"){
  //         travelDetailsInfo.push(
  //           {
  //             name: this.getData(item + index1, "label", step)['label'],
  //             value: this.checkCustom(item + index1,"",step)
  //           }
  //         )
  //       }
  //       else if(item === "whatDamaged"){
  //           travelDetailsInfo.push(
  //             {
  //              name: this.getData('whatDamaged' + index1, "label", step)['label'],
  //              value: this.getData('whatDamaged' + index1, "", step),
  //            })     
  //         if(this.dataStoreService.get('fnol-claimtype-details').uiData['whatDamaged'+index1]?.id === '5'){
  //         travelDetailsInfo.push(
  //           {
  //            name: this.getData('provideDetailsDamaged' + index1, "label", step)['label'],
  //            value: this.getData('provideDetailsDamaged' + index1, "", step),
  //          })
  //        }
  //       }
  //       else if (item ==="heading"){
  //         travelDetailsInfo.push(
  //           {
  //             name: "",
  //             value: "heading"
  //           }
  //         )
  //       }
  //       else{
  //         travelDetailsInfo.push(
  //           {
  //             name: this.getData(item + index1, "label", step)['label'],
  //             value: this.getData(item + index1, "", step)
  //           }
  //         )
  //       }

  //     });
  //     // travelMainArr.push({
  //     //   "featureTypeText": $localize`:@@addInfor:Additional Insurance #` + labelIndex,
  //     //   "featureTypeCd": "additionalInsurance" + labelIndex,
  //     //   "questionAnswer": travelArr
  //     // })
  //   })
  //   //return travelMainArr
  // }
  else if(this.dataStoreService.get('fnol-claimtype-details').uiData['itemClaimType'][0]?.name ==='myItems'){
    let bagDamage = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addItems").fields
    let travelMainArr = []
    bagDamage.forEach((data, i) => {
      let travelArr=[]
      const index = i==0 ? "" : i;
      const labelIndex = index!=""?(index-1):index;
      const matches = data.name.match(/(\d+)/);
      const index1 = matches != null ? matches[0] :"";
      const bagDamageDetails = ["bagItem", "nameValue", "yearPurchased","heading"];
      bagDamageDetails.forEach((item, i) => {
         if(item === "nameValue"){
          travelDetailsInfo.push(
            {
              name: this.getData(item + index, "label", step)['label'],
              value: this.checkCustom(item + index,"",step)
            }
          )

        }
        else if (item ==="heading"){
          travelDetailsInfo.push(
            {
              name: "",
              value: "heading"
            }
          )
        }
        else{
          travelDetailsInfo.push(
            {
              name: this.getData(item + index, "label", step)['label'],
              value: this.getData(item + index, "", step)
            }
          )
        }

      });
      
    })
  }
   }
   
   if(uiData['travelBags'] ==="Lost or Stolen"){
    let lostStolenDetails = ['stolenLost','stolenHappened','stolenItem','stolenItemWas','stolenDate','heading']
    lostStolenDetails.forEach(fieldName => {
      if(fieldName === "heading"){
        travelDetailsInfo.push(
          {
            name: "Bags and/or Items Details",
            value: "heading",
          })
      }
      else {
          travelDetailsInfo.push(
            {
              name: this.getData(fieldName, "label", step)['label'],
              value: this.getData(fieldName, "", step),
            }
          ) 
         }
       })
       let lostStolen = Utils.getFieldFromStructure(this.dataStoreService.get(step).uiStructure, "addStolenMain").fields

       let travelMainArr = []
       lostStolen.forEach((data, i) => {
         let travelArr=[]
         const index = i==0 ? "" : i;
         const labelIndex = index!=""?(index-1):index;
         const matches = data.name.match(/(\d+)/);
      const index1 = matches != null ? matches[0] :"";
         const lostStolenDetails = ["bagItemStolen", "nameStolenValue", "yearStolenPurchased","heading"];
         lostStolenDetails.forEach((item, i) => {
            if(item === "nameStolenValue"){
             travelDetailsInfo.push(
               {
                 name: this.getData(item + index1, "label", step)['label'],
                 value: this.checkCustom(item + index1,"",step)
               }
             )
           }
           else if (item ==="heading"){
             travelDetailsInfo.push(
               {
                 name: "",
                 value: "heading"
               }
             )
           }
           else{
             travelDetailsInfo.push(
               {
                 name: this.getData(item + index1, "label", step)['label'],
                 value: this.getData(item + index1, "", step)
               }
             )
           }
   
         });
         
       })
       
      
       if(uiData['authorityReport'] ==="Yes" && uiData['authorityReport'] !==null){
       
          travelDetailsInfo.push(
            {
              name: this.getData('authorityReport', "label", step)['label'],
              value: this.checkCustom('authorityReport',"",step)
            },
            {
              name: this.getData('authorityCopyReport', "label", step)['label'],
              value: this.checkCustom('authorityCopyReport',"",step)
            }
          )
       }
       else {
        travelDetailsInfo.push(
          {
            name: this.getData('authorityReport', "label", step)['label'],
            value: this.checkCustom('authorityReport',"",step)
          }
        )
       }
      if(uiData['authorityReport'] ==="Yes" && uiData['authorityReport'] !==null && uiData['authorityCopyReport'] ==="Yes" && uiData['authorityCopyReport'] !==null){
        let copyReport = ['nameAuthority','referenceNumber','phoneNumber','emailStolen','myAddressLookup']
         copyReport.forEach(fieldName => {
          if(fieldName === "myAddressLookup"){
            travelDetailsInfo.push(
              {
                name: this.getData(fieldName, "label", step)['label'],
                value: this.getData(fieldName+"val", "", step)? this.getData(fieldName+"val", "", step): this.getData(fieldName, "", step).formatted_address?this.getData(fieldName, "", step).formatted_address:this.getData(fieldName, "", step)
              }
            )
          }
          else if(fieldName === "phoneNumber" && this.uiData['phoneNumber'] !== null){
            travelDetailsInfo.push(
              {
                name: 'Phone Number',
                value: this.getData("phoneNumber", "", step)['phoneCode'].hasOwnProperty('label')? this.getData("phoneNumber", "", step)['phoneCode']['label']+ "-"  + this.getData("phoneNumber", "", step)['phoneNumber'] : this.getData("phoneNumber", "", step)['phoneCode'] + "-"  + this.getData("phoneNumber", "", step)['phoneNumber']
              })
          }
          else {
          travelDetailsInfo.push(
            {
              name: this.getData(fieldName, "label", step)['label'],
              value: this.checkCustom(fieldName,"",step)
            }
          )
          }
        })
      }
   }
  }
  })
 }

 if(this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimTypeIllness'].find(x=>x.name  === 'claimingOther') !== undefined){
  let travelBenefitType =["heading","claimingForOtherFor"]
  travelBenefitType.forEach(fieldName => {
        
    if(fieldName=="heading"){
      travelDetailsInfo.push(
        {
          name: 'Other',
          value: 'heading'
        })
    }
    else {
        travelDetailsInfo.push(
          {
            name: this.getData(fieldName, "label", step)['label'],
            value: this.getData(fieldName, "", step),
          }
        )
      
    }
})
 }
  
      console.log(travelDetailsInfo,"travel details")
       return travelDetailsInfo
    }
    
    getPaymentData(step){
          let paymentObj = []
          let paymentObj2 = []
          let norField = ["payeeNameBank", "bankName"]
          let norField1 = ["", ""]
          let uiData = this.dataStoreService.get(step).uiData;
          //let creditCardInfoDetails = []
          if (this.dataStoreService.get('fnol-payment-details').uiData['paymentChannel'] === 'Electronic Bank Transfer') {
      
            paymentObj.push( {
              name: this.getData("paymentChannel", "label", step)['label'],
              value:this.getData("paymentChannel", '', step)
            })
      
              paymentObj.push(
                {
                  name: this.getData('payeeNameBank', "label", step)['label'],  
                  value: this.getData('payeeNameBank', "", step)
      
                })
            
            paymentObj.push(
              {
                name: this.getData('bankName', "label", step)['label'],  
                value: this.getData('bankName', "", step)?.name
    
              })
      
            paymentObj.push({
              name: "Account Number",
              value: this.getData("accountNumber", '', step)
            })
            console.log(paymentObj,"payment")
            return paymentObj
          }
          else if(this.getData("paymentChannel", '', step) === 'cheque'){
            paymentObj.push({
              name: this.getData("paymentChannel", "label", step)['label'],
              value: this.getData("paymentChannel", '', step)==='cheque'? "Cheque":""
            })
            paymentObj.push(
              {
                name: this.getData("payeeName", "label", step)['label'],  
                value: this.getData("payeeName", "", step)
    
              })
          
              console.log(paymentObj,"payment")
            return paymentObj
          }
        
        
       
      }
    
    getUploadDetails(step) {
      let obj = []
      if (this.dataStoreService.get(step).uiData.customFileUpload) {
        let fileList = this.dataStoreService.get(step).uiData.customFileUpload.uploads;
  
        fileList.forEach((element, i) => {
          obj.push(
            {
              name: "Upload" + (i + 1) + ":",
              value: element.name
            }
  
          )
        });
  
        return obj;
      } else {
        return obj;
      }
    }
    private maskValue(value, item?: string): string {
      if (item === 'accountNo') {
        return value.replace(/./g, '*');
      }
      return `*****${value.substring(5, value.length)}`;
    }
    personalAcidant(){
      let personalDetails = []

      personalDetails.push(
        {
          name: this.getData('claimBenefits', "label", this.step2)['label'] === "Select Claim Type"?"What is the cause of our claim?":"",
          value: this.dataStoreService.get(this.step2).uiData["claimBenefits"]
        })
   
      let arrysub1 =[ "heading","accidentOccurred","injuriesSustained","accidentDate", "timeIncident","accidentLocationLookup","firstVisit","diagnosis","trafficAccident"]
       let arraysub2 = ["heading" , "chooseClaimType"]    
      arrysub1.forEach(key => {
        if(key ==="heading"){
          personalDetails.push(
            {
            name: "Personal  Accident Details",
            value :"heading"
            })
        }
       else if (key ==="accidentLocationLookup"){
        if(this.getData(key, "", this.step2)){
          personalDetails.push(
            {
              name: this.getData(key, "label", this.step2)['label'],
            value: this.getData( key +"val", "", this.step2)? this.getData(key +"val", "", this.step2): this.getData( key, "", this.step2).formatted_address?this.getData( key, "", this.step2).formatted_address:this.getData( key, "", this.step2)
              })
       }
       }
      else if(key === "diagnosis"){
        if(key.indexOf('diagnosis') > -1){
          personalDetails.push(
            {
             name: this.getData('diagnosis', "label", this.step2)['label'],
             value: this.getData('diagnosis', "", this.step2),
           })
        }
        if(this.dataStoreService.get('fnol-claimtype-details').uiData['diagnosis']?.id === '13'){
          personalDetails.push(
          {
           name: this.getData('otherDia', "label", this.step2)['label'],
           value: this.getData('otherDia', "", this.step2),
         })
       }
      }
       
        else{
          if(this.dataStoreService.get(this.step2)?.uiData[key] !== 'diagnosis'){
            if(this.getData(key, "", this.step2)){
              personalDetails.push(
                {
                  name: this.getData(key, "label", this.step2)['label'],
                value: this.getData(key, "", this.step2)
                  }
             
           
           
          )
            }
            }
              
      }
      });
     
      arraysub2.forEach(key => {
        if(key ==="heading"){
          personalDetails.push(
            {
            name: "Personal  Accident Benefits",
            value :"heading"
            })
        }
       
       
        else{
         
          const newArray=[];
          this.personalAccident =[]
          this.dataStoreService.get('fnol-claimtype-details').uiData['chooseClaimType'].forEach(element => {
            if(CHECKBOXLITEPERSONAL.find(field=>field.key===element.name)!=undefined){
              let headingValue =CHECKBOXLITEPERSONAL.find(field=>field.key===element.name).heading;
              newArray.push(headingValue);          
             this.personalAccident.push(headingValue)
            }
            
          });
          
            personalDetails.push(
              {
                name: this.getData(key, "label", this.step2)['label'],
                value: newArray.join(', ')
                }
           
         
         
        )
      }
      });
   
   
   
      console.log(personalDetails, "djjdjdjd")
      return this.personalDetails = personalDetails ;
   
   
     
    }
  
  
    getCreditTravel(data?){
      let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addTreatment")?.fields
      let travelMainArr = [];
      let travelArr=[]  ;
      let uiData = this.dataStoreService.get(this.step1Name).uiData;
      travelInsurance.forEach((data, i) => {

        const index = i > 0 ? i : "";
        const labelIndex = index!=""?(index-1):index;
        console.log(labelIndex + "label index ");
       
        const nonBagNoEMp = ["nameClinic","dateOfAdmission","timeAdmission","dateOfDischarge","timeDischarge"];
        if (uiData["admittedHospital"]=== "Yes"){
          nonBagNoEMp.forEach((item, i) => {
            if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
              travelArr.push({
                "name": this.getData(item + index, "label", this.step1Name)['label'] ,
                "value": this.getData(item + index, "", this.step1Name)
                 
              });
    
            }else{
              if(this.getData(item + index, "", this.step2)){
                travelArr.push({
                  "name": this.getData(item + index, "label", this.step1Name)['label'] ,
                  "value": this.getData(item + index, "", this.step1Name)
                });
              }
             
            }
    
          });
             
        }
        else{
          travelArr =[]
        }
      
      })
      if(data && travelArr.length>0){
        let array =[ {
          name: "Hospitalisation details",
          value :"heading"
          }]
            
            travelArr =  [...array,...travelArr]
      }
       return travelArr
    }
  
    getDammageDelayDetails(){
      const damageDelayObj=[];
      let addvale =[ "nameClinic","dateOfAdmission","timeAdmission","timeDischarge"]
      let uiData = this.dataStoreService.get(this.step2).uiData;
      
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step2).uiStructure, "addTreatment").fields;
    let travelMainArr = []
    let travelArr=[]
    travelInsurance.forEach((data, i) => {
      const index = i > 0 ? i+1 : "";
      const labelIndex = index!=""?(index-1):index;
      const nonBagNoEMp = [ "nameClinic","dateOfAdmission","timeAdmission","timeDischarge"]
      nonBagNoEMp.forEach((item, i) => {
        if(this.getData(item + index, "label", this.step2)["type"] == "checkbox" ){
          travelArr.push({
            name: this.getData(item + index, "label", this.step2)['label']+labelIndex,
                  value: this.getData(item + index, "", this.step2)
          });
  
        }else{
          if(this.getData(item + index, "", this.step2)){
            travelArr.push({
              name: this.getData(item + index, "label", this.step2)['label']+labelIndex,
              value: this.getData(item + index, "", this.step2)
            });
          }
         
        }
  
      });
    })
  
    return travelArr
    }
  
  accendentalmediaclr(e?){
       let DetailsData=[]
      let arraysub4 = [
       "heading","westernMedicine",
      "dentalTreatment",
      "mobileAdd",
        ,'sum']
  arraysub4.forEach(key => {
        if(key ==="heading"){
          DetailsData.push(
            {
            name: "Cost of Treatment",
            value :"heading"
            })
        }    
        else{
    
            DetailsData.push(
              {
                name: key ===  "westernMedicine" ?'Medical Treatment' : key ===  "dentalTreatment" ? 'Dental Treatment' : key ===  "mobileAdd" ? 'Mobility Aid': key ===  "sum"? 'Total Claim Amount':'',
                value:  this.dataStoreService.get(this.step2).uiData["medicalExpense"][key]? "SGD" +" "+this.decimalConverter(this.dataStoreService.get(this.step2).uiData["medicalExpense"][key],'SG') :"SGD 0.00"
                }
           
         
         
        )
      }    
    })
    if(e){
      if(e && DetailsData.length>1 ){
        DetailsData[0].value =''
        let array =[ {
          name: "Accident Medical Reimbursement",
          value :"heading"
          }]
            
            DetailsData= [...array,...DetailsData]
      }
    else{
      DetailsData =[] 
    } 
    }
    
    return DetailsData;
   }
  
  
   featureOrDisclosed(){
   
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addFracture").fields
      let travelMainArr = []
      let travelArr=[]
      let uiData = this.dataStoreService.get(this.step1Name).uiData;
      travelInsurance.forEach((data, i) => {
        const index = i > 0 ? i : "";
        const labelIndex = index!=""?(index-1):index;
        console.log(labelIndex + "label index ");
       
        const nonBagNoEMp = [ "siteFracture","typeFracture"];
        if( uiData["sustainFracture"] === "Yes"){
          nonBagNoEMp.forEach((item, i) => {
            if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
              travelArr.push({
                "name": this.getData(item + index, "label", this.step1Name)['label'] ,
                "value": this.getData(item + index, "", this.step1Name)
                 
              });
    
            }else{
              if(this.getData(item + index, "", this.step2)){
                travelArr.push({
                  "name": this.getData(item + index, "label", this.step1Name)['label'] ,
                  "value": this.getData(item + index, "", this.step1Name)
                });
              }
             
            }
    
          });
        }
        
      })
      console.log(travelMainArr ," travelMainArr travelMainArr travelMainArr travelMainArr travelMainArr travelMainArr travelMainArr")
      return travelArr
   }
  
  siteDisclosed(){
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addDislocation").fields
    let travelMainArr =[];
    let travelArr=[]
    travelInsurance.forEach((data, i) => {
      const index = i > 0 ? i : "";
      const labelIndex = index!=""?(index-1):index;
      console.log(labelIndex + "label index ");
     
      const nonBagNoEMp = ["siteDislocation"];
      if(uiData["sustainDislocation"] === "Yes"){
        nonBagNoEMp.forEach((item, i) => {
          if(this.getData(item + index, "label", this.step1Name)["type"] == "checkbox" ){
            travelArr.push({
              "name": this.getData(item + index, "label", this.step1Name)['label'] ,
              "value": this.getData(item + index, "", this.step1Name)
               
            });
    
          }else{
            if(this.getData(item + index, "", this.step2)){
              travelArr.push({
                "name": this.getData(item + index, "label", this.step1Name)['label'] ,
                "value": this.getData(item + index, "", this.step1Name)
              });
            }
           
          }
    
        });
      
      }
     
      
    })
    console.log(travelArr ," travelMainArr travelMainArr travelMainArr travelMainArr travelMainArr travelMainArr travelMainArr")
    return travelArr
  }
  getPersonalAccident() {
    
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let personalAccidentDetails = ["sustainFracture" ,"sustainDislocation"];
    let personalAccidentDetailsInfo = [];
    personalAccidentDetails.forEach((res ,i)=> {
      if(uiData[personalAccidentDetails[i]]=== "Yes" && uiData["sustainFracture"] === "Yes"  && personalAccidentDetails[i]=== "sustainFracture")
      personalAccidentDetailsInfo.push(
        {
          "name": this.getData(res, "label", this.step1Name)['label'],
          "value": this.getData(res, "", this.step1Name), 
        })
        else if (uiData[personalAccidentDetails[i]] === "NO" && uiData["sustainFracture"] === "NO" && personalAccidentDetails[i]=== "sustainFracture"){
          personalAccidentDetailsInfo.push(
            {
              "name": this.getData(res, "label", this.step1Name)['label'],
              "value": this.getData(res, "", this.step1Name),
            }) 
        }
        else if( uiData[personalAccidentDetails[i]]=== "Yes" && uiData["sustainDislocation"] === "Yes" &&  personalAccidentDetails[i]=== "sustainDislocation"){
          personalAccidentDetailsInfo.push(
            {
              "name": this.getData(res, "label", this.step1Name)['label'],
              "value": this.getData(res, "", this.step1Name),
            })
        }
        else{
          personalAccidentDetailsInfo.push(
            {
              "name": this.getData(res, "label", this.step1Name)['label'],
              "value": this.getData(res, "", this.step1Name),
            })
        }
  
  
    })
   
  console.log(personalAccidentDetailsInfo,"personalAccidentDetailsInfo")
    return personalAccidentDetailsInfo
    
  }
    hospitalDocInfor(e?){
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addDoctorMain").fields
    let travelMainArr =[];
    let travelArr=[]
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    if(uiData["doctorInfo"]=== "Yes"){
      travelInsurance.forEach((data, i) => {
        const index = i==0 ? "" : i;
        const labelIndex = index!=""?(index-1):index;
        console.log(labelIndex + "label index ");
        if(i>0 && !e){
          travelArr.push({
            "name": "" ,
            "value":  ""
             
          });
        }
       
        const nonBagNoEMp = ["nameDoctorClinic","doctorName","mobileDoctor","emailDoctor"];
        nonBagNoEMp.forEach((item, i) => {
          if(item === "mobileDoctor" && this.uiData['mobileDoctor' + index]?.phoneNumber!== ''){
            travelArr.push(
              {
                "name": 'Contact Number',
                "value": this.dataStoreService.get(this.step1Name).uiData['mobileDoctor'+index]?.hasOwnProperty('label')? this.dataStoreService.get(this.step1Name).uiData['mobileDoctor'+index]?.phoneCode.label + "-"  + this.dataStoreService.get(this.step1Name).uiData['mobileDoctor'+index]?.phoneNumber : this.dataStoreService.get(this.step1Name).uiData['mobileDoctor'+index]?.phoneCode + "-"  + this.dataStoreService.get(this.step1Name).uiData['mobileDoctor'+index]?.phoneNumber
              })
          }
          else if(item === "emailDoctor" && this.uiData['emailDoctor' + index] !==''){
            travelArr.push({
              "name": this.getData("emailDoctor" + index, "label", this.step1Name)['label'] ,
              "value": this.getData("emailDoctor" + index, "", this.step1Name)
            });
          }
          else{
            if(item !== "mobileDoctor" && item !== "emailDoctor"){
              travelArr.push({
                "name": this.getData(item + index, "label", this.step1Name)['label'] ,
                "value": this.getData(item + index, "", this.step1Name)
              });
            }
           
          }
    
        });
                
        
      })
    }else{
      travelArr =[]
    }
    
    console.log(travelArr ,"travelMainArr")
    return travelArr
  }
  
  drInfor(){
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let personalAccidentDetails = ["doctorInfo"];
    let personalAccidentDetailsInfo = [];
    personalAccidentDetails.forEach((res ,i)=> {
      if(uiData["doctorInfo"]=== "Yes"){
        if(this.getData(res, "", this.step1Name)){
          personalAccidentDetailsInfo.push(
            {
              "name": this.getData(res, "label", this.step1Name)['label'],
              "value": this.getData(res, "", this.step1Name), 
            })
        }
        
      }
      else{
        if(this.getData(res, "", this.step1Name)){
          personalAccidentDetailsInfo.push(
            {
              "name": this.getData(res, "label", this.step1Name)['label'],
              "value": this.getData(res, "", this.step1Name),
            })
        }
       
      }
     })
    
     this.hsotDrInfo = personalAccidentDetailsInfo;
    return personalAccidentDetailsInfo
  }
  
  
    dailyHospitalIncome(data?){
      let uiData = this.dataStoreService.get(this.step1Name).uiData;
      let personalAccidentDetails = ["admittedHospital"];
      let personalAccidentDetailsInfo = [];
      personalAccidentDetails.forEach((res ,i)=> {
        if(uiData["admittedHospital"]=== "Yes"){
          personalAccidentDetailsInfo.push(
            {
              "name": this.getData(res, "label", this.step1Name)['label'],
              "value": this.getData(res, "", this.step1Name),
            })
        }
        else{
          personalAccidentDetailsInfo.push(
            {
              "name": this.getData(res, "label", this.step1Name)['label'],
              "value": this.getData(res, "", this.step1Name),
            })
        }
       })
       console.log(personalAccidentDetailsInfo,"Daily Hospital Income")
      if(data && personalAccidentDetailsInfo.length>0){
        let array =[ {
          name: "Daily Hospital Income",
          value :"heading"
          }]
            
            personalAccidentDetailsInfo = [...array,...personalAccidentDetailsInfo]
      }
      return personalAccidentDetailsInfo
    }
  
  getOtherDetails(){
    let otherDetailsInfo= []
    otherDetailsInfo.push(
      {
        name: this.getData('claimBenefits', "label", this.step2)['label'] === "Select Claim Type"?"What is the cause of our claim?":"",
        value: this.dataStoreService.get(this.step2).uiData["claimBenefits"]
      })
    let otherInfo=['']
    otherDetailsInfo.push({
      name: this.getData('claimForOther', "label", this.step2)['label'] ,
      value: this.getData('claimForOther', "", this.step2)
       
    });
    return otherDetailsInfo
  }
  
  mediaclLeave(e?){
   
    let travelInsurance = Utils.getFieldFromStructure(this.dataStoreService.get(this.step1Name).uiStructure, "addLeave").fields
      let travelMainArr = []
      let travelArr=[]
      travelInsurance.forEach((data, i) => {
        if(i>0 && !e){
          travelArr.push({
            "name": "" ,
            "value":  ""
             
          });
        }
       
        const index = i > 0 ? i : "";
        const labelIndex = index!=""?(index-1):index;
        console.log(labelIndex + "label index ");
       
        const nonBagNoEMp = [ "dateOfStart","dateOfEnd"];
        nonBagNoEMp.forEach((item, i) => {
           
          
        if(this.getData(item + index, "", this.step2)){
              travelArr.push({
                "name": this.getData(item + index, "label", this.step1Name)['label'] ,
                "value": this.getData(item + index, "", this.step1Name)
              });
            }
           
          
  
        });
        
      })
       if(e && travelArr.length>0){
        
        let array =[ {
          name: "Medical Leave",
          value :"heading"
          }]
            
            travelArr = [...array,...travelArr]
       }
        return travelArr
  }
  
  detailsMedicalLeave(e?){
    let uiData = this.dataStoreService.get(this.step1Name).uiData;
    let personalAccidentDetails = ["employed","dateOfExpected"];
    let personalAccidentDetailsInfo = [];
    personalAccidentDetails.forEach((res ,i)=> {
      if( this.getData(res, "", this.step1Name)){
        personalAccidentDetailsInfo.push(
          {
            "name": this.getData(res, "label", this.step1Name)['label'],
            "value": this.getData(res, "", this.step1Name)  
          })
      }

      }
       
     )
    
    return personalAccidentDetailsInfo
  }
  
  PermanentDisablement(e?){
   let  permanentDi =["dateOfDisability","typeDisability"];
   let disablityData =[];
   permanentDi.forEach((res ,i)=> {
      if(this.getData(res, "", this.step1Name) ){
        disablityData.push(
          {
            "name": this.getData(res, "label", this.step1Name)['label'],
            "value": this.getData(res, "", this.step1Name)  
          })
      }
  }
   
  )
   if(e && disablityData.length>0){
        
        let array =[ {
          name: "Permanent Disablement",
          value :"heading"
          }
      ]
            
          disablityData = [...array,...disablityData]
       }
  return disablityData;
  }
  
  accidentalDeath(e?){
    let  permanentDi = ["dateOfDeath","localTimePersonal","causeDeath" ,"autopsy","fullName","relationName","phoneNumberPersonal","emailAddress"];
    let disablityData =[];
    permanentDi.forEach((res ,i)=> {
      
        if(res === "phoneNumberPersonal"){
        disablityData.push(
          {
            "name": 'Phone Number',
            "value": this.getData("phoneNumberPersonal", "", this.step1Name)['phoneCode'].hasOwnProperty('label')? this.getData("phoneNumberPersonal", "", this.step1Name)['phoneCode']['label']+ "-"  + this.getData("phoneNumberPersonal", "", this.step1Name)['phoneNumber'] : this.getData("phoneNumberPersonal", "", this.step1Name)['phoneCode'] + "-"  + this.getData("phoneNumberPersonal", "", this.step1Name)['phoneNumber']
          })
       
        }
        else if(res === 'autopsy'){
          if (this.uiData['autopsy'] === 'No' && this.uiData['autopsy'] !== null) {
  
            disablityData.push(
              {
                name: this.getData('autopsy', "label", this.step1Name)['label'],
                value: this.getData('autopsy', "", this.step1Name),
              },
              {
                name: this.getData('reason', "label", this.step1Name)['label'],
                value: this.getData('reason', "", this.step1Name),
              })
  
          }
          else {
            disablityData.push({
              name: this.getData('autopsy', "label", this.step1Name)['label'],
              value: this.getData('autopsy', "", this.step1Name),
            })
          }
        }
        else {
          disablityData.push(
            {
              "name": this.getData(res, "label", this.step1Name)['label'],
              "value": this.getData(res, "", this.step1Name)  
            })
        }
   }
    
   )
   if(e && disablityData.length >0){
        
    let array =[ {
      name: "Accidental Death Details",
      value :"heading"
      }]
        
      disablityData = [...array,...disablityData]
   }
   
   return disablityData;
  }
  otherDeath(e?){
    let objet ;
    if(this.getData("claimOther", "", this.step1Name)){
      objet = {
        "name": this.getData("claimOther", "label", this.step1Name)['label'],
        "value": this.getData("claimOther", "", this.step1Name)  
      }
    }
   if(e && objet){
    let array =[ {
      name: "Other",
      value :"heading"
      } ]
      objet = [...array,objet]
   }
    return objet
    }


  fravtureList(e){
    let personalAccidentDetails = ["sustainFracture" ,"sustainDislocation"];
    let objet ={};
    if(this.getData( e, "", this.step1Name)){
      objet = {
        "name": this.getData( e, "label", this.step1Name)['label'],
        "value": this.getData( e, "", this.step1Name)  
      }
    }
   
    return objet
  }
  headingArray(){
    let array =[ {
      name: "Accident Medical Reimbursement",
      value :"heading"
      },
       ]
        
        return [...array,...this.accidentalDeath()]
  }

  featureOrDisclosedPdf(){
    
    let array =[ {
      name: "Fracture  or Dislocation",
      value :"heading"
      }] 
     let  data =[...array, this.fravtureList("sustainFracture") ,...this.featureOrDisclosed(),this.fravtureList("sustainDislocation"),...this.siteDisclosed()]
     return data
  }
  hospitalDoctoreInfoPdf(){
    let array =[ {
      name: "Hospital and Doctor Information",
      value :"heading"
      }
       ]  
        let  data =[...array, ...this.drInfor(), ...this.hospitalDocInfor("a")];
        return data
  }
  


  personalAccidantPayload(){
    let finalArray=[]
    let personalPayLoad=[]
    personalPayLoad=personalPayLoad.concat(this.personalAcidant());
    this.personalAccident.forEach(res =>{
      if(res ==="Accident Medical Reimbursement/ Daily Hospital Income/ Fracture or Dislocation"){
        personalPayLoad = personalPayLoad.concat(this.accendentalmediaclr("a"),this.dailyHospitalIncome('a'),this.getCreditTravel("a"),this.featureOrDisclosedPdf())
      }
      if(res ==="Medical Leave Benefit"){
        personalPayLoad =personalPayLoad.concat(this.mediaclLeave("a"),this.detailsMedicalLeave())
      }
      if(res ==="Permanent Disablement"){
        personalPayLoad =personalPayLoad.concat(this.PermanentDisablement("a"))
      }
      if(res ==="Accidental Death"){
       personalPayLoad = personalPayLoad.concat(this.accidentalDeath("a"))
      }
      if(res ==="Other"){
        personalPayLoad =personalPayLoad.concat(this.otherDeath("a"))
      }
    })
    personalPayLoad = personalPayLoad.concat(this.hospitalDoctoreInfoPdf())
    console.log(personalPayLoad,"personal pay load")
    return  personalPayLoad
   // return this.personalAcidant().concat(this.accendentalmediaclr("a"),this.dailyHospitalIncome('a'),this.getCreditTravel("a"),this.featureOrDisclosedPdf(),this.mediaclLeave("a"),this.detailsMedicalLeave(),this.PermanentDisablement("a"),this.accidentalDeath("a"),this.otherDeath("a"),this.hospitalDoctoreInfoPdf())
  }
  

 
  findtype(){
    
    let arrray =this.uiData.chooseClaimType;
    // foundData = ["medicalExpenseClaim","accidentalDeath","permanentDisability","leaveBenefit","otherClaim]
    this.foundmedical =arrray.some(obj =>obj.value ==="medicalExpenseClaim")
    this.accidentalDeatBoolean = arrray.some(obj =>obj.value === "accidentalDeath")
    this.permanantdiablity = arrray.some(obj =>obj.value === "permanentDisability")
    this.leavebenifit = arrray.some(obj =>obj.value === "leaveBenefit")
    this.otherClaimShow = arrray.some(obj =>obj.value === "otherClaim")
   
  }

  
}

